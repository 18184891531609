export function JSON_to_URLEncoded(element, key, l) {
	let list = l || [];

	if (typeof element == 'object') {
		for (var idx in element)
			JSON_to_URLEncoded(
				element[idx],
				key ? key + '[' + idx + ']' : idx,
				list
			);
	} else {
		list.push(key + '=' + encodeURIComponent(element));
	}
	return list.join('&');
}
