import { capitalizeFirstLetter } from '../../utils/utils';
import { createValidationRule, ValidationRule } from '../validation_rule';

export const IS_ARRAY_RULE_NAME = 'isArrayRule';

export function isArrayRule(inputName: string): ValidationRule {
	return createValidationRule(
		IS_ARRAY_RULE_NAME,
		`${capitalizeFirstLetter(
			inputName
		)} must not be malformed (this is not your fault! Sorry!)`,
		(inputValue, formFields, otherRules) => {
			try {
				return Array.isArray(JSON.parse(inputValue));
			} catch {
				return false;
			}
		}
	);
}
