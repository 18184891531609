import { useContext } from 'react';
import LinkButton from '../../shared/components/link_button';
import { UserContext } from '../../users/user_context';

const LoggedInIndicator: React.FC = () => {
	const { currentUser, firebase } = useContext(UserContext);

	return (
		<>
			{currentUser && (
				<div className='absolute md:relative top-24 md:top-auto right-6 md:right-auto text-xs md:text-sm py-1 lg:py-3 px-2 lg:px-4 font-serif text-center border-l border-primary inline-block'>
					<div className=''>Welcome, {currentUser.displayName}</div>
					<div className='text-xs'>
						<LinkButton
							text='Log out'
							onClick={() => {
								firebase.auth().signOut();
							}}></LinkButton>
					</div>
				</div>
			)}
		</>
	);
};

export default LoggedInIndicator;
