import { applyValidationRules } from '../../../../common/validation/functions/apply_validation_rules';
import { IValue } from '../../../../common/validation/validation_rule';

import { IFormField } from '../form_field';
import { FieldValidationResult } from '../../../../common/validation/field_validation_result';

export function validateField(
	inputField: IFormField,
	formFields: IFormField[]
): FieldValidationResult {
	const allFields: IValue[] = [];

	for (const field of formFields) {
		allFields.push({ name: field.name, value: field.value });
	}

	return applyValidationRules(
		inputField.validationRules,
		inputField.value,
		allFields,
		inputField.name
	);
}
