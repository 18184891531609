const NoSearchResults = () => (
	<div className='flex items-center h-full justify-center py-6'>
		<span className='mr-6 border-r-4 border-secondary pr-6'>
			<span className='text-5xl font-serif'>!</span>
		</span>
		<span className='inline-flex flex-col items-center'>
			<span className='text-lg pl-6 font-serif'>No varieties found.</span>
			<span className='text-xs text-gray-500'>Try different terms.</span>
		</span>
	</div>
);

export default NoSearchResults;
