import { SUGAR_LEVELS_MINIMUM_YEAR } from '../../../../../../../common/definitions';
import SugarLevelsFieldModel from '../../../../../../../common/forms/pomona_entry/sugar_levels_field_model';
import { getYearsSince } from '../../../../../../../common/utils/utils';

export interface SugarLevelsYearSelectProps {
	sugarLevels: SugarLevelsFieldModel;
	currentEntryIndex: number;
	handleChange: Function;
	name: string;
}

const SugarLevelsYearSelect: React.FC<SugarLevelsYearSelectProps> = ({
	sugarLevels,
	currentEntryIndex,
	handleChange,
	name,
}) => (
	<select
		className='shadow rounded py-1 px-1'
		name={`sugarLevel-year`}
		value={sugarLevels.levels[currentEntryIndex].year}
		onChange={(e) => {
			const newEntry = { ...sugarLevels.levels[currentEntryIndex] };
			newEntry.year = parseInt(e.target.value);
			sugarLevels.levels[currentEntryIndex] = newEntry;

			const event = {
				target: {
					name: name,
					value: JSON.stringify(sugarLevels),
				},
			} as React.ChangeEvent<HTMLInputElement>;
			handleChange(event);
		}}>
		{getYearsSince(SUGAR_LEVELS_MINIMUM_YEAR)
			.reverse()
			.map((year) => (
				<option key={`year-${year}`} value={year}>
					{year}
				</option>
			))}
	</select>
);

export default SugarLevelsYearSelect;
