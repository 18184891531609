export const VARIETY_TYPES = ['apple', 'pear', 'other'] as const;
export type VarietyType = typeof VARIETY_TYPES[number];

export const APPLE_CATEGORIES = [
	'bittersweet',
	'bittersharps',
	'sweets',
	'sharps',
	'cookers',
	'desserts-eaters',
	'crab',
];
export type AppleCategory = typeof APPLE_CATEGORIES[number];

export const PEAR_CATEGORIES = ['perry pear', 'cooking pear'];
export type PearCategory = typeof PEAR_CATEGORIES[number];

export const OTHER_CATEGORIES = ['other'];
export type OtherCategory = typeof OTHER_CATEGORIES[number];

export const VARIETY_CATEGORIES = [
	...APPLE_CATEGORIES,
	...PEAR_CATEGORIES,
	...OTHER_CATEGORIES,
] as const;
export type VarietyCategory = AppleCategory | PearCategory | OtherCategory;

export const VARIETY_STATUSES = [
	'unknown',
	'widespread',
	'uncommon',
	'rare',
	'critically-endangered',
	'extinct',
] as const;
export type VarietyStatus = typeof VARIETY_STATUSES[number];

export const TIME_TO_MATURITY = [
	'unknown',
	'quick',
	'average',
	'slow',
] as const;
export type TimeToMaturity = typeof TIME_TO_MATURITY[number];

export const FRUIT_BEARING = [
	'unknown',
	'tip',
	'spur',
	'partial tip-bearer',
] as const;
export type FruitBearing = typeof FRUIT_BEARING[number];

export const POLLINATION_GROUPS = [
	'unknown',
	'1/A',
	'2/B',
	'3/C',
	'4/D',
	'5/E',
	'6/F',
	'7/G',
] as const;
export type PollinationGroup = typeof POLLINATION_GROUPS[number];

export const REGIONS = [
	'Northern Europe',
	'North America',
	'Central Europe',
	'South Europe',
	'Western Europe',
	'Eastern Europe',
	'Western Asia',
	'Central Asia',
	'South East Asia',
	'East Asia',
	'Oceania',
	'North Africa',
	'Central Africa',
	'South Africa',
	'Central America',
	'South America',
] as const;
export type Region = typeof REGIONS[number];

export const FAVOURABILITY = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10] as const;
export type Favourability = typeof FAVOURABILITY[number];

export const RATING = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10] as const;
export type Rating = typeof RATING[number];

export const SEARCH_WILDCARD = '(**!!SEARCH_WILDCARD!!**)';
export type SearchWildcard = typeof SEARCH_WILDCARD;

export const EmptyStringifiedArray = '';

// 'Eve'?
export const MINIMUM_PERSON_NAME_LENGTH = 3;
export const MAXIMUM_PERSON_NAME_LENGTH = 100;

// for now we also use these for pest, disease, place names, etc
export const MINIMUM_VARIETY_NAME_LENGTH = 5;
export const MAXIMUM_VARIETY_NAME_LENGTH = 35;

export const MINIMUM_HISTORY_LENGTH = 15; // any more than this and people get annoyed
export const MAXIMUM_HISTORY_LENGTH = 2000;

export const MINIMUM_SEARCH_LENGTH = 3;

// we also use this for "where to source"?
export const MAXIMUM_ORIGIN_PLACE_LENGTH = 200;

export const MINIMUM_TIME_TO_FRUIT_YEARS = 2;
export const MAXIMUM_TIME_TO_FRUIT_YEARS = 10;

export const SUGAR_LEVELS_MINIMUM_YEAR = 1980;
export const MINIMUM_SUGAR_LEVEL = 1.02; // in specific gravity
export const MAXIMUM_SUGAR_LEVEL = 1.15; // in specific gravity

export const SUGAR_UNITS = ['specific gravity', 'brix'] as const;
export type SugarUnit = typeof SUGAR_UNITS[number];

export const MINIMUM_PH_LEVEL = 2.5;
export const MAXIMUM_PH_LEVEL = 5;

export const WORD_CLOUD_WORDS_ALLOWED = 3; // how many total words per entry
export const WORD_CLOUD_MIN_CHAR_LENGTH = 3; // minimum *total* length in characters
export const WORD_CLOUD_MAX_CHAR_LENGTH = 20; // maximum *total* length in characters

export const MAX_IMAGE_SIZE_MB = 3;

export const CONTACT_EMAIL_ADDRESS = 'info@ourpomona.org';
