import { SugarUnit } from '../../../common/definitions';
import {
	brixToSpecificGravity,
	specificGravityToBrix,
} from '../../../common/utils/convert_sugar_levels';

export function displaySugarAmount(
	amount: string,
	amountUnit: SugarUnit,
	displayUnit: SugarUnit
): string {
	if (!amount || amount === '') return 'Missing ';

	if (amountUnit === displayUnit) return amount;

	if (displayUnit === 'specific gravity')
		return (
			brixToSpecificGravity(Number(amount))?.toFixed(3).toString() || ''
		);

	return specificGravityToBrix(Number(amount))?.toFixed(3).toString() || '';
}
