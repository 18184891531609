import { clientConfig } from '../config/client_config';
import { SubmitStatus } from '../../common/forms/submit_status';
import { JSON_to_URLEncoded } from '../../common/utils/other_utils';

export async function submitToServer<T>(
	route: string,
	body?: any
): Promise<SubmitStatus<T>> {
	const url = `${clientConfig.nodeProtocol}://${clientConfig.nodeServer}:${clientConfig.nodePort}/${route}`;

	try {
		let response = await fetch(url, {
			method: 'POST',
			mode: 'cors',
			cache: 'no-cache',
			headers: {
				Accept: 'application/x-www-form-urlencoded',
				'Content-Type': 'application/x-www-form-urlencoded',
			},
			body: body !== undefined ? JSON_to_URLEncoded(body) : undefined, // url_encoded rather than pure JSON because CORS stops us sending json "cross-domain", ie to a different port
		});

		if (response.status === 200) {
			// all good. we trust ourselves to have sent back a submit status
			const result = (await response.json()) as SubmitStatus<T>;
			return result;
		} else {
			return {
				submitted: true,
				success: false,
				message: `Could not reach server (${response.status}).`,
			};
		}
	} catch (err) {
		console.error(err);
		return {
			submitted: true,
			success: false,
			message: 'Could not reach server.',
		};
	}
}
