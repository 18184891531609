import React from 'react';
import { EmptyStringifiedArray } from '../../../../../common/definitions';
import { IFormField } from '../../form_field';
import Input from '../../input';
import { InputChangeSignature } from '../input_change_signature';

export function createTextArrayFormField(
	label: string,
	name: string,
	addMoreLabel: string,
	placeholder?: string
): IFormField {
	return {
		name: name + '[]', // signals to body-parser on the server to deserialise this as an array
		renderInput: (
			handleChange: (event: InputChangeSignature) => void,
			inputObj: IFormField
		) => {
			return (
				<Input
					key={`inputContainer-${inputObj.name}`}
					type={'textarray'}
					handleChange={handleChange}
					inputObj={inputObj}
				/>
			);
		},
		label,
		value: EmptyStringifiedArray,
		valid: true,
		errors: [],
		touched: false,
		validationRules: [],
		placeholder: placeholder,
		addMoreLabel: addMoreLabel,
	};
}
