import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router';
import { NavLink } from 'react-router-dom';
import BrowseOptions from '../../common/server_responses/fetch_browse/browse_options';
import BrowseResult from '../../common/server_responses/fetch_browse/browse_result';
import { VarietySummary } from '../../common/server_responses/variety_summary/variety_summary';
import SERVER_ROUTES from '../../common/server_routes/server_routes';
import getFromServer from '../shared/get_from_server';
import LoadingIndicator from '../shared/loading/loading_indicator';
import PageTitle from '../shared/ui/page_title';
import SearchResultDisplay from './search_result';

interface BrowseRouteParams {
	letter: string;
}

const Browse: React.FC = () => {
	const abortController = new AbortController();

	const [isLoading, setIsLoading] = useState<boolean>(false);

	const { letter } = useParams<BrowseRouteParams>();
	const [availableLetters, setAvailableLetters] = useState<string[]>([]);

	const [varieties, setVarieties] = useState<VarietySummary[]>([]);

	useEffect(() => {
		const doFetch = async function () {
			setIsLoading(true);
			const response = await getFromServer<BrowseResult>(
				SERVER_ROUTES.FetchBrowse,
				{
					firstLetter: letter,
				} as BrowseOptions,
				abortController
			);

			if (response.success && response.result) {
				setVarieties(response.result.varieties || []);
				setAvailableLetters(response.result.availableLetters || []);
			}

			setIsLoading(false);
		};

		doFetch();
		return () => {
			abortController.abort();
		};
		// eslint-disable-next-line
	}, [letter]);

	const allLetters = Array.from('ABCDEFGHIJKLMNOPQRSTUVWXYZ');

	return (
		<>
			<Helmet>
				<title>Browse - Our Pomona</title>
			</Helmet>
			<div>
				<PageTitle title={'Browse All Varieties'} />
				<div className='flex flex-col align-middle items-center'>
					<div className='flex flex-row flex-wrap'>
						{allLetters.map((l, i) =>
							availableLetters.find((al) => al === l) ===
							undefined ? (
								<span
									key={`browse-letter-${l}`}
									className='mx-1 text-sm lg:text-xl text-gray-300'>
									{l}
								</span>
							) : (
								<NavLink
									key={`browse-letter-${l}`}
									to={`/browse/${l}`}
									className='mx-1 font-bold text-green-700 text-sm lg:text-xl'>
									{l}
								</NavLink>
							)
						)}
					</div>
					<LoadingIndicator
						loading={isLoading}
						color='green'
						size={50}
						className='my-6'
					/>
					{!isLoading && (
						<div className='mt-9'>
							{varieties.map((v, i) => (
								<SearchResultDisplay
									key={`browse-result-${i}`}
									summary={v}
									index={i}
								/>
							))}
						</div>
					)}
				</div>
			</div>
		</>
	);
};

export default Browse;
