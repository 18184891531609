import React from 'react';
import { IFormField } from '../../form_field';
import Input from '../../input';
import { InputChangeSignature } from '../input_change_signature';
import { TextInputType } from '../input_types';

export function createTextFormField(
	label: string,
	name: string,
	type: TextInputType, // text or textarea
	placeholder?: string,
	defaultValue = ''
): IFormField {
	return {
		name: name,
		renderInput: (
			handleChange: (event: InputChangeSignature) => void,
			inputObj: IFormField
		) => {
			return (
				<Input
					key={`inputContainer-${inputObj.name}`}
					type={type} // text or textarea
					handleChange={handleChange}
					inputObj={inputObj}
				/>
			);
		},
		label,
		value: defaultValue,
		valid: true,
		errors: [],
		touched: false,
		validationRules: [],
		placeholder: placeholder,
	};
}
