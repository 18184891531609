import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { IFormField } from './form_field';
import InnerInput from './inner_input';
import { InputChangeSignature } from './inputs/input_change_signature';
import { InputType } from './inputs/input_types';

export interface ToggledInputProps {
	type: InputType;
	inputObj: IFormField;
	handleChange: (event: InputChangeSignature) => void;
}

function toggleInput(
	disabled: boolean,
	e: React.MouseEvent,
	inputObj: IFormField,
	handleChange: (event: InputChangeSignature) => void
) {
	e.preventDefault();

	inputObj.toggle = {
		disabled: disabled,
		enableMessage: inputObj.toggle?.enableMessage || '',
		disableMessage: inputObj.toggle?.disableMessage || '',
	};
	const event = {
		target: {
			name: inputObj.name,
			value: inputObj.value,
		},
	} as React.ChangeEvent<HTMLInputElement>;

	handleChange(event);
}

const ToggledInput: React.FC<ToggledInputProps> = ({
	type,
	inputObj,
	handleChange,
}) => {
	const { toggle } = inputObj;

	return (
		<span className='inline-flex align-middle justify-center px-3 lg:px-0 w-full items-center flex-col text-xs lg:text-base'>
			{toggle === undefined && (
				<InnerInput
					type={type}
					inputObj={inputObj}
					handleChange={handleChange}
				/>
			)}
			{toggle !== undefined && (
				<>
					{toggle.disabled ? (
						<input
							type='hidden'
							value={undefined}
							name={inputObj.name}
						/>
					) : (
						<InnerInput
							type={type}
							inputObj={inputObj}
							handleChange={handleChange}
						/>
					)}
					<button
						className={
							'text-xs my-2 mr-3 py-3 px-2' +
							(!toggle.disabled
								? ' text-red-400'
								: ' text-blue-600')
						}
						onClick={(e) => {
							toggleInput(
								!toggle.disabled,
								e,
								inputObj,
								handleChange
							);
						}}>
						{toggle.disabled ? (
							toggle.enableMessage
						) : (
							<span className='inline-flex flex-row items-center'>
								<FontAwesomeIcon
									icon='times-circle'
									size='2x'
									title={`Don't Include ${inputObj.label}`}
									className='mr-1'
								/>
								{toggle.disableMessage}
							</span>
						)}
					</button>
				</>
			)}
		</span>
	);
};

export default ToggledInput;
