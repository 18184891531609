import { FieldValidationResult } from '../field_validation_result';
import { EmptyStringifiedArray } from '../../definitions';
import { IS_ARRAY_RULE_NAME } from '../rules/rule_is_array';
import {
	includesRequiredRule,
	includesRule,
	IValue,
	ValidationRule,
} from '../validation_rule';

export function applyValidationRules(
	rules: ValidationRule[],
	value: string,
	allFields: IValue[],
	valueName: string
): FieldValidationResult {
	const req = includesRequiredRule(rules);

	// if field is empty and unrequired then return true
	if (
		!req &&
		(value === undefined ||
			value.length === 0 ||
			value === EmptyStringifiedArray)
	) {
		return {
			valid: true,
			errors: [],
		};
	}

	// is this an array?
	const isArray = includesRule(rules, IS_ARRAY_RULE_NAME);

	if (isArray) {
		// apply the rules to individual members

		// server-side this is already an array
		// client-side this is still a string so may need to be deserialised
		const innerValues = Array.isArray(value)
			? value
			: (JSON.parse(value) as string[]);

		// console.log(innerValues);

		let result: FieldValidationResult = {
			valid: true,
			errors: [],
		};

		innerValues.forEach((v, i) => {
			// NB allFields here is all the OTHER fields... currently there's no need to validate
			// inner fields against themselves, but we can add that here if necessary later
			let innerResult = getValidationResultForValue(
				v,
				allFields,
				rules.filter((a) => a.name !== IS_ARRAY_RULE_NAME)
			);
			if (!innerResult.valid) {
				result.valid = false;
			}

			result.errors = result.errors.concat(
				innerResult.errors.map((e) => `${e} (#${i + 1})`)
			);
		});

		return result;
	} else {
		return getValidationResultForValue(value, allFields, rules);
	}
}

function getValidationResultForValue(
	value: string,
	allFields: IValue[],
	rules: ValidationRule[]
): FieldValidationResult {
	// otherwise check all rules
	let result: FieldValidationResult = {
		valid: true,
		errors: [],
	};

	for (const rule of rules) {
		if (!rule.validate(value, allFields, rules)) {
			result.errors.push(rule.message);
			result.valid = false;
		}
	}

	return result;
}
