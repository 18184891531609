import React from 'react';
import { NavLink } from 'react-router-dom';

export interface HeaderLinkProps {
	to: string;
	text: string;
	additionalClassName?: string;
	setMenuOpen: Function;
}

const HeaderLink: React.FC<HeaderLinkProps> = ({
	to,
	text,
	additionalClassName,
	setMenuOpen,
}) => (
	<NavLink
		to={to}
		onClick={() => setMenuOpen(false)}
		className={
			additionalClassName +
			' border-primary border-l-2 px-1 py-1 lg:px-2 xl:px-3 xl:py-2  text-sm md:text-base lg:text-lg 2xl:text-xl font-serif block md:inline-block lg:mt-0 text-black hover:text-white '
		}>
		{text}
	</NavLink>
);

export default HeaderLink;
