import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import { useHistory } from 'react-router';
import { MINIMUM_SEARCH_LENGTH } from '../../common/definitions';
import { useMediaQuery } from 'react-responsive';

const NameSearchBox = () => {
	const [name, setName] = useState<string>('');
	const history = useHistory();

	const isMedium = useMediaQuery({ minWidth: 1024 });

	return (
		<span className='invisible lg:visible text-center mx-2 lg:mx-6'>
			<form>
				{isMedium && (
					<FontAwesomeIcon
						icon={faSearch}
						color='#10b981'
						size='lg'
					/>
				)}
				<input
					value={name}
					onChange={(e) => setName(e.target.value)}
					className='border text-sm p-1 w-32 ml-1 xl:text-md xl:p-2 xl:w-64 xl:ml-3 rounded'
					type='text'
					placeholder='Search for variety'
				/>
				<button
					type='submit'
					className='button ml-1 lg:ml-3 mt-3 xl:mt-0 text-xs'
					disabled={name.length < MINIMUM_SEARCH_LENGTH}
					onClick={(e) => {
						e.preventDefault();
						setName('');
						history.push('/search/' + name);
					}}>
					Search
				</button>
			</form>
		</span>
	);
};

export default NameSearchBox;
