import React from 'react';
import { WhisperSpinner } from 'react-spinners-kit';
import { BaseProps } from '../base_props';

type LoadingIndicatorProps = {
	loading: boolean;
	size?: number;
	color?: string;
};

const LoadingIndicator = (props: LoadingIndicatorProps & BaseProps) => {
	const { loading, size, color, className, ...rest } = props;
	return (
		<>
			{loading && (
				<span
					className={
						'inline-flex align-middle justify-center ' + className
					}
					{...rest}>
					<WhisperSpinner
						size={size}
						backColor={color || '#A7F3D0'}
						frontColor={'green'}
						loading={loading}
					/>
				</span>
			)}
		</>
	);
};

export default LoadingIndicator;
