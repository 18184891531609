import { SearchFormModel } from '../../common/forms/search/search_form_model';
import SearchFormValidationMap from '../../common/forms/search/search_form_validation_map';
import SERVER_ROUTES from '../../common/server_routes/server_routes';
import { clientConfig } from '../config/client_config';
import ErrorMessagePage from '../shared/ui/error_message_page';
import LoadingIndicator from '../shared/loading/loading_indicator';
import RecaptchaComponent from '../shared/recaptcha_component';

import PageTitle from '../shared/ui/page_title';
import SearchFormDefinition from './search_form_definition';
import SearchResult from '../../common/forms/search/search_results/search_result';
import SearchResultsList from './search_results_list';
import useSubmitForm from '../shared/hooks/use_submit_form';
import SearchFormBase from '../../common/forms/search/search_form_base';
import { useParams } from 'react-router';
import { submitToServer } from '../shared/submit_to_server';
import { getRecaptchaToken } from '../shared/hooks/get_recaptcha_token';
import { SubmitStatus } from '../../common/forms/submit_status';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

interface SearchParams {
	name: string;
}

async function doSearch(
	name: string,
	setSubmitStatus: (s: SubmitStatus<SearchResult>) => void
) {
	if (!name) return;

	setSubmitStatus({
		submitted: true,
		success: undefined,
		result: undefined,
	});

	const token = await getRecaptchaToken('search');

	if (token) {
		const result = await submitToServer<SearchResult>(
			SERVER_ROUTES.Search,
			{
				name: name,
				recaptchaToken: token,
			}
		);

		setSubmitStatus(result);
	}
}

const Search = () => {
	const {
		submitStatus,
		renderFormInputs,
		isFormValid,
		onSubmit,
		recaptchaReady,
		setRecaptchaReady,
		setSubmitStatus,
	} = useSubmitForm<
		SearchFormModel,
		SearchFormValidationMap,
		SearchFormBase,
		SearchResult
	>(
		SERVER_ROUTES.Search,
		new SearchFormValidationMap(),
		new SearchFormDefinition()
	);

	// if we have navigated here with a particular search parameter, perform that search
	const { name } = useParams<SearchParams>();

	useEffect(() => {
		doSearch(name, setSubmitStatus);
	}, [name, setSubmitStatus, recaptchaReady]);

	// this should probably be moved into submitStatus and set properly
	const submitting =
		submitStatus.submitted && submitStatus.success === undefined;

	return (
		<>
			<Helmet>
				<title>Search - Our Pomona</title>
			</Helmet>
			<div className='w-full text-center'>
				<PageTitle title={'Search'} />

				{!submitting && (
					<form className='flex flex-col' onSubmit={onSubmit}>
						{renderFormInputs()}
						<button
							className='button text-lg lg:text-2xl self-center mt-3 lg:mt-6 mb-6'
							type='submit'
							disabled={
								!isFormValid() || !recaptchaReady || submitting
							}>
							{!recaptchaReady ? (
								<LoadingIndicator loading={true} size={30} />
							) : (
								'Search'
							)}
						</button>
					</form>
				)}
				{submitting && (
					<div className='w-full text-center'>
						<LoadingIndicator
							loading={true}
							className='mt-6 lg:mt-12 mb-3 lg:mb-6'
							size={80}
						/>
					</div>
				)}
				{submitStatus.submitted &&
					submitStatus.success === true &&
					submitStatus.result && (
						<SearchResultsList result={submitStatus.result} />
					)}
				{submitStatus.submitted && submitStatus.success === false && (
					<ErrorMessagePage
						message={'Sorry, something went wrong.'}
						detailedError={submitStatus.message}
					/>
				)}
				<RecaptchaComponent
					action='search'
					sitekey={clientConfig.recaptchaSiteKey}
					onReady={() => {
						setRecaptchaReady(true);
					}}
					hideText={submitStatus.submitted}
				/>
			</div>
		</>
	);
};

export default Search;
