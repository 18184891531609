import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { NavLink } from 'react-router-dom';
import { VarietySummary } from '../../common/server_responses/variety_summary/variety_summary';
import HistorySnippet from '../shared/components/history_snippet';
import StarRating from '../variety_detail/details/star_rating';
import VarietyTypeIcon from '../variety_detail/variety_header/variety_type_icon';
import noImagesImg from './../assets/images/default_fruit.png';

const lineHeight = '2rem';

export interface SearchResultDisplayProps {
	index: number;
	summary: VarietySummary;
}

const SearchResultDisplay: React.FC<SearchResultDisplayProps> = ({
	summary,
	index,
}) => (
	<div
		className='search-results-item flex flex-row w-full p-1 lg:p-3 align-middle border-b items-center'
		style={{ lineHeight: lineHeight }}>
		<span
			className='text-xs text-gray-400 hidden lg:inline-block'
			style={{ lineHeight: lineHeight }}>
			{index + 1}
		</span>
		<span className='mx-1 lg:mx-3 hidden md:inline-block'>
			<VarietyTypeIcon type={summary.type} />
		</span>
		<img
			src={summary.imageUrl || noImagesImg}
			className='max-h-16 rounded'
			alt={summary.name}
		/>
		<span
			className='px-1 lg:px-3 font-bold text-sm lg:text-xl border-r break-words'
			style={{ minWidth: '18%', maxWidth: '18%' }}>
			<NavLink to={`/variety/${summary.id}`}> {summary.name}</NavLink>
		</span>
		{summary.synonyms && (
			<span
				className='text-xs text-gray-500  font-normal px-2 border-r border-l'
				style={{
					maxWidth: '5rem',
					minWidth: '5rem',
					wordBreak: 'break-word',
				}}>
				{summary.synonyms?.map((s) => (
					<div className='my-1'>{s}</div>
				))}
			</span>
		)}
		<span
			className='pl-2 lg:pl-3 text-left text-xs lg:text-sm inline-flex justify-center items-center'
			style={{ maxWidth: '40%' }}>
			<HistorySnippet history={summary.history} />
		</span>
		<span className='hidden md:inline-block ml-auto'>
			<StarRating
				ratingOutOfTen={summary.favourability}
				className='self-center'
				size={
					useMediaQuery({ minWidth: 1024 }) ? 'text-2xl' : 'text-xs'
				}
			/>
		</span>
	</div>
);

export default SearchResultDisplay;
