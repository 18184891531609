import { capitalizeFirstLetter } from '../../utils/utils';
import { createValidationRule, ValidationRule } from '../validation_rule';

export function minValueRule(
	inputName: string,
	minValue: number
): ValidationRule {
	return createValidationRule(
		'minValue',
		`${capitalizeFirstLetter(inputName)} should be at least ${minValue}`,
		(inputValue, formFields, otherRules) =>
			Number.parseFloat(inputValue) >= minValue
	);
}
