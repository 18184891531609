import EditVarietyFormValidationMap from '../../common/forms/edit_variety/edit_variety_form_validation_map';
import AddVarietyFormDefinition from '../add_variety/add_variety_form_definition';
import { IFormField } from '../shared/forms/form_field';
import { createHiddenFormField } from '../shared/forms/inputs/create/create_hidden_form_field';

export default class EditVarietyFormDefinition extends AddVarietyFormDefinition {
	varietyId: IFormField;

	constructor(varietyId: number) {
		super();
		this.varietyId = {
			...createHiddenFormField('varietyId', varietyId.toString()),
			validationRules: { ...new EditVarietyFormValidationMap() }
				.varietyId,
		};
	}
}
