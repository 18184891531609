import { IFormField } from './form_field';
import { IS_ARRAY_RULE_NAME } from '../../../common/validation/rules/rule_is_array';
import ValidationMapBase from '../../../common/validation/validation_map_base';
import { includesRule } from '../../../common/validation/validation_rule';

// gets the values to submit to the server from the form fields
export function getModelFromForm<
	FormModel,
	TValidationMap extends ValidationMapBase
>(form: IFormField[], validationMap: TValidationMap): FormModel {
	let r: { [index: string]: any } = {};

	form.forEach((field) => {
		let rules = validationMap[field.name];

		// get rules for array types too
		if (field.name.endsWith('[]') && rules === undefined) {
			rules = validationMap[field.name.slice(0, -2)];
		}

		if (
			includesRule(rules, IS_ARRAY_RULE_NAME) ||
			includesRule(rules, 'isSugarLevels')
		) {
			// we know this is a stringified object, convert back to a real array/object
			r[field.name] = JSON.parse(field.value);
		} else {
			// just submit the value
			r[field.name] = field.value;
		}
	});

	return r as FormModel;
}
