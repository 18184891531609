import { SUGAR_LEVELS_MINIMUM_YEAR } from '../definitions';

export type HasIndex = {
	[key: string]: any;
};

export function capitalizeFirstLetter(str: string): string {
	return str.charAt(0).toUpperCase() + str.slice(1);
}

export function average(arr: number[]): number {
	if (!arr || arr === undefined || arr.length === 0) return 0;

	return arr.reduce((a, v, i) => (a * i + v) / (i + 1));
}

export const urlEncode = (data: Object): string => {
	return encodeURIComponent(JSON.stringify(data));
};

export const urlDecode = (data: string): Object => {
	return JSON.parse(decodeURIComponent(data));
};

export function nameof<T>(key: keyof T, instance?: T): keyof T {
	return key;
}

export function getYearsSince(startYear: number) {
	let currentYear = new Date().getFullYear(),
		years = [];
	startYear = startYear || SUGAR_LEVELS_MINIMUM_YEAR;
	while (startYear <= currentYear) {
		years.push(startYear++);
	}
	return years;
}

export function isNumeric(str: string) {
	if (typeof str === 'number' || typeof str === 'bigint') return true;
	if (typeof str != 'string') return false; // we only process strings!
	return (
		!isNaN(str as any) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
		!isNaN(parseFloat(str))
	); // ...and ensure strings of whitespace fail
}
