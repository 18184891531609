import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IFormField } from '../../form_field';
import { InputChangeSignature } from '../input_change_signature';

export interface InputArrayAddFieldButtonProps {
	inputObj: IFormField;
	entireArray: string[];
	handleChange: (event: InputChangeSignature) => void;
	defaultValue: string;
}

const InputArrayAddFieldButton: React.FC<InputArrayAddFieldButtonProps> = ({
	inputObj,
	entireArray,
	handleChange,
	defaultValue,
}) => {
	const { name } = inputObj;
	return (
		<button
			className='smallLink mt-4 justify-self-center inline-flex items-center justify-center'
			onClick={(e) => {
				e.preventDefault();
				entireArray.push(defaultValue);
				const event = {
					target: {
						name: name,
						value: JSON.stringify([...entireArray]),
					},
				} as React.ChangeEvent<HTMLInputElement>;
				handleChange(event);
			}}>
			<FontAwesomeIcon icon='plus-circle' color='#A7F3D0' size='2x' />
			<span className='text-green-700 ml-3'>
				{inputObj.addMoreLabel || 'Another'}
			</span>
		</button>
	);
};

export default InputArrayAddFieldButton;
