import React from 'react';

import SERVER_ROUTES from '../../common/server_routes/server_routes';
import { clientConfig } from '../config/client_config';
import ErrorMessagePage from '../shared/ui/error_message_page';

import LoadingIndicator from '../shared/loading/loading_indicator';
import RecaptchaComponent from '../shared/recaptcha_component';
import SuccessPage from '../shared/ui/success_page';
import VarietyViewModel from '../../common/models/variety_view_model';
import useSubmitForm from '../shared/hooks/use_submit_form';
import EditVarietyFormDefinition from './edit_variety_form.definition';
import EditVarietyFormValidationMap from '../../common/forms/edit_variety/edit_variety_form_validation_map';
import { EditVarietyFormModel } from '../../common/forms/edit_variety/edit_variety_form_model';

export interface EditVarietyFormProps {
	variety: VarietyViewModel;
}

function getFormFromModel(
	variety: VarietyViewModel
): EditVarietyFormDefinition {
	const form = new EditVarietyFormDefinition(variety.id);

	for (let field in form) {
		// convert the existing value to a string (for complex objects like arrays)
		// but use a regex to remove start/end quotes erroneously being added
		if (variety[field]) {
			const existingValue = JSON.stringify(variety[field]).replace(
				/(^")|("$)/g,
				''
			);

			form[field].value = existingValue;

			// if this is a toggle-able field, we want it OPEN if there's an existing value in it, closed otherwise
			if (
				form[field] &&
				form[field].value.length > 0 &&
				form[field].value !== '[]' &&
				form[field].toggle
			) {
				const toggle = form[field].toggle;
				if (toggle) {
					toggle.disabled = false;
				}
			}
		}
	}

	return form;
}

const EditVarietyForm: React.FC<EditVarietyFormProps> = ({ variety }) => {
	const editFormDefinition = getFormFromModel(variety);

	const {
		submitStatus,
		renderFormInputs,
		onSubmit,
		recaptchaReady,
		setRecaptchaReady,
		isFormValid,
	} = useSubmitForm<
		EditVarietyFormModel,
		EditVarietyFormValidationMap,
		EditVarietyFormDefinition,
		undefined
	>(
		SERVER_ROUTES.SubmitEditVariety,
		new EditVarietyFormValidationMap(),
		editFormDefinition // we reuse everything, just reset the default values here
	);

	return (
		<>
			<p className='my-4'>
				All our data is sourced from the community. Thank you for
				improving Our Pomona!
			</p>

			{!submitStatus.submitted && (
				<form className='flex flex-col' onSubmit={onSubmit}>
					{renderFormInputs()}
					<button
						className='button text-2xl self-center mt-6'
						type='submit'
						disabled={!isFormValid() || !recaptchaReady}>
						{!recaptchaReady ? (
							<LoadingIndicator loading={true} />
						) : (
							'Edit Variety'
						)}
					</button>
				</form>
			)}
			{submitStatus.submitted && submitStatus.success === undefined && (
				<LoadingIndicator loading={true} />
			)}
			{submitStatus.submitted && submitStatus.success === true && (
				<SuccessPage
					message='Thank you! Your suggested edits are
				awaiting approval.'
				/>
			)}
			{submitStatus.submitted && submitStatus.success === false && (
				<ErrorMessagePage
					message='Sorry, something went wrong.'
					detailedError={submitStatus.message}
				/>
			)}
			<RecaptchaComponent
				action='addVariety'
				sitekey={clientConfig.recaptchaSiteKey}
				onReady={() => {
					setRecaptchaReady(true);
				}}
				hideText={submitStatus.submitted}
			/>
		</>
	);
};

export default EditVarietyForm;
