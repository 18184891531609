import * as React from 'react';
import { injectStyle, isBrowser } from '../utils';
import useScript from './use_script';

export interface RecaptchaProps {
	sitekey: string;
	hideDefaultBadge?: boolean;
}

export function useRecaptcha({
	sitekey,
	hideDefaultBadge = false,
}: RecaptchaProps) {
	const [recaptcha, setRecaptcha] = React.useState<Recaptcha | undefined>();
	const [ready, setReady] = React.useState<boolean>(false);

	const script = `https://www.google.com/recaptcha/api.js?render=${sitekey}`;

	const status = useScript(script);

	React.useEffect(() => {
		if ((window as any).grecaptcha) {
			(window as any).grecaptcha.ready(() => {
				setRecaptcha((window as any).grecaptcha);
				setReady(true);
			});
		}
	}, [status]);

	const getToken = async (action: string): Promise<string> => {
		if (recaptcha) {
			const result = await recaptcha.execute(sitekey, { action });
			return result;
		} else {
			console.log('no recaptcha script');
			return '';
		}
	};

	React.useEffect(() => {
		if (isBrowser && hideDefaultBadge) {
			injectStyle('.grecaptcha-badge { visibility: hidden; }');
		}
	}, [hideDefaultBadge]);

	return { ready, getToken };
}

interface Recaptcha {
	ready(): Promise<void>;
	render(
		container: HTMLElement,
		config: { theme?: 'dark' | 'light'; size?: 'compact' | 'normal' }
	): void;
	execute(sitekey: string, config: { action: string }): string;
}
