import { clientConfig } from '../../config/client_config';

export async function getRecaptchaToken(
	action: string
): Promise<string | undefined> {
	const grecaptcha = (window as any).grecaptcha;

	if (!grecaptcha) {
		console.log(`Can't get recaptcha token - api not ready.`);
		return undefined;
	}

	const token = await grecaptcha.execute(clientConfig.recaptchaSiteKey, {
		action: action,
	});

	if (token) return token;

	console.log(
		'Failed to get recaptcha token. Check the site key, check the domain list. NH.'
	);
	return undefined;
}
