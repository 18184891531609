import { SEARCH_WILDCARD, VARIETY_STATUSES } from '../../definitions';
import { isVarietyStatus } from '../checks/checks';
import {
	createValidationRule,
	includesRule,
	ValidationRule,
} from '../validation_rule';
import { CanBeSearchWildcardRule } from './rule_can_be_search_wildcard';

export function isVarietyStatusRule(): ValidationRule {
	return createValidationRule(
		'status',
		`Must be one of ${VARIETY_STATUSES.join(', ')}`,
		(inputValue, formFields, otherRules) =>
			isVarietyStatus(inputValue) ||
			(includesRule(otherRules, CanBeSearchWildcardRule) &&
				inputValue === SEARCH_WILDCARD)
	);
}
