export interface IValue {
	name: string;
	value: string;
}

export interface ValidationRule {
	name: string;
	message: string;
	validate: (
		value: string,
		allValues: IValue[],
		otherRules: ValidationRule[]
	) => boolean;
}

export function createValidationRule(
	ruleName: string,
	errorMessage: string,
	validateFunc: (
		value: string,
		allValues: IValue[],
		otherRules: ValidationRule[]
	) => boolean
): ValidationRule {
	return {
		name: ruleName,
		message: errorMessage,
		validate: validateFunc,
	};
}

export function includesRequiredRule(rules: ValidationRule[]): boolean {
	return (
		rules &&
		rules.length > 0 &&
		rules.filter((r) => r.name === 'required').length > 0
	);
}

export function includesRule(
	rules: ValidationRule[],
	ruleName: string
): boolean {
	return (
		rules &&
		rules.length > 0 &&
		rules.filter((r) => r.name === ruleName).length > 0
	);
}

// export function passwordMatchRule() {
// 	return createValidationRule(
// 		'passwordMatch',
// 		`passwords do not match`,
// 		(inputValue, formObj) => inputValue === formObj.password.value
// 	);
// }
