import { SEARCH_WILDCARD, VARIETY_CATEGORIES } from '../../definitions';
import { isVarietyCategory } from '../checks/checks';
import {
	createValidationRule,
	includesRule,
	ValidationRule,
} from '../validation_rule';
import { CanBeSearchWildcardRule } from './rule_can_be_search_wildcard';

export function isVarietyCategoryRule(): ValidationRule {
	return createValidationRule(
		'varietyCategory',
		`Must be one of ${VARIETY_CATEGORIES.join(', ')}`,
		(inputValue, formFields, otherRules) =>
			isVarietyCategory(inputValue) ||
			(includesRule(otherRules, CanBeSearchWildcardRule) &&
				inputValue === SEARCH_WILDCARD)
	);
}
