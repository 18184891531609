import { useContext } from 'react';
import { UserContext } from './user_context';

const MustBeLoggedIn: React.FC = ({ children }) => {
	const { currentUser } = useContext(UserContext);

	return <>{currentUser && <>{children}</>}</>;
};

export default MustBeLoggedIn;
