import React from 'react';
import Router from './routes/router';

import { UserProvider } from './users/user_context_provider';

const UserWrapper: React.FC = () => {
	return (
		<UserProvider>
			<Router />
		</UserProvider>
	);
};

export default UserWrapper;
