import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IFormField } from '../../form_field';
import { InputChangeSignature } from '../input_change_signature';

export interface InputArrayRemoveFieldButtonProps {
	inputObj: IFormField;
	entireArray: string[];
	index: number;
	handleChange: (event: InputChangeSignature) => void;
}

const InputArrayRemoveFieldButton: React.FC<InputArrayRemoveFieldButtonProps> = ({
	inputObj,
	index,
	entireArray,
	handleChange,
}) => {
	const { name } = inputObj;

	return (
		<button
			className='ml-4 text-red-400'
			onClick={(e) => {
				e.preventDefault();
				entireArray.splice(index, 1);
				const event = {
					target: {
						name: name,
						value: JSON.stringify([...entireArray]),
					},
				} as React.ChangeEvent<HTMLInputElement>;
				handleChange(event);
			}}>
			<FontAwesomeIcon icon={faTimesCircle} />
		</button>
	);
};

export default InputArrayRemoveFieldButton;
