import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMediaQuery } from 'react-responsive';
import { includesRequiredRule } from '../../../../common/validation/validation_rule';
import { IFormField } from '../form_field';
import InputHelpLink from './input_help_link';

export interface InputLabelProps {
	inputObj: IFormField;
}

const InputLabel: React.FC<InputLabelProps> = ({ inputObj }) => {
	const { label, validationRules, icon, prompt, helpItemId } = inputObj;

	const isWide = useMediaQuery({ minWidth: 1024 });

	const isInputRequired = includesRequiredRule(validationRules);

	return (
		<label className='text-grey-darker mb-2 lg:mb-0 text-sm font-bold lg:w-1/4 text-center lg:pr-6 lg:pl-3 inline-flex flex-col'>
			<span>
				<span className={isInputRequired ? 'text-red-400' : ''}>
					{label}
				</span>
				{!isWide && helpItemId && (
					<InputHelpLink helpItemId={helpItemId} float='left' />
				)}
				{isInputRequired ? (
					<span className='text-red-400'> (*)</span>
				) : (
					''
				)}
				{icon && (
					<span className='inline-flex self-center items-center w-8'>
						<FontAwesomeIcon
							icon={icon}
							color='#A7F3D0'
							className='text-2xl mr-3'
						/>
					</span>
				)}
				{/* {helpItemId && (
					<a
						href={`${ROUTES.HELP}#${helpItemId}`}
						target='_blank'
						rel='noreferrer'
						className='ml-1 lg:ml-2 self-end'>
						<FontAwesomeIcon icon='question-circle' />
					</a>
				)} */}
			</span>
			<span>
				{prompt && (
					<div className='text-gray-600 text-xs mb-2 mt-1 text-center'>
						{prompt}
					</div>
				)}
			</span>
		</label>
	);
};

export default InputLabel;
