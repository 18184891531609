import React from 'react';
import { IFormField } from '../form_field';
import DatePicker from 'react-date-picker';
import { InputChangeSignature } from './input_change_signature';
import { SHARED_INPUT_CSS_CLASSES } from './shared_input_details';

export interface InputDatepickerProps {
	inputObj: IFormField;
	handleChange: (event: InputChangeSignature) => void;
}

const InputDatepicker: React.FC<InputDatepickerProps> = ({
	inputObj,
	handleChange,
}) => {
	const { touched, valid, value, name } = inputObj;

	return (
		<DatePicker
			className={
				(touched && !valid ? ' border border-red-400 ' : '') +
				SHARED_INPUT_CSS_CLASSES +
				(inputObj.toggle && !inputObj.toggle.disabled
					? ' w-2/3 lg:w-1/3 '
					: ' w-2/3 lg:w-1/3 ')
			}
			onChange={(date) => {
				// convert from react-date-picker date to ISO string, for simplicity server-side
				const d = new Date(date.toString()).toISOString();

				// react-date-picker handles its events differently than our use-form hook
				// so pull out only what use-form needs for handleChange
				const event = {
					target: { name: name, value: d },
				} as React.ChangeEvent<HTMLInputElement>;
				handleChange(event);
			}}
			value={new Date(value)}
			name={name}
		/>
	);
};

export default InputDatepicker;
