import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { HashLink } from 'react-router-hash-link';
import ROUTES from '../../../routes/client_routes';

export interface InputHelpLinkProps {
	helpItemId: string;
	float?: string;
}

// <HashLink
// 	to={{ pathname: ROUTES.HELP, hash: helpItemId }}
// 	className={
// 		'justify-center mx-2 lg:mx-3 text-gray-400 ' +
// 		(float === 'left' ? ' self-start ' : ' self-end ')
// 	}>
// 	<FontAwesomeIcon icon='question-circle' />
// </HashLink>

const InputHelpLink: React.FC<InputHelpLinkProps> = ({ helpItemId, float }) => (
	<a
		href={`${ROUTES.HELP}#${helpItemId}`}
		target='_blank'
		rel='noreferrer'
		className={
			'justify-center mx-2 lg:mx-3 text-gray-400 ' +
			(float === 'left' ? ' self-start ' : ' self-end ')
		}>
		<FontAwesomeIcon icon='question-circle' />
	</a>
);

export default InputHelpLink;
