import React from 'react';
import { IFormField } from '../../form_field';
import { InputChangeSignature } from '../input_change_signature';
import { SHARED_INPUT_CSS_CLASSES } from '../shared_input_details';

export interface InputArrayTextInputFieldProps {
	inputObj: IFormField;
	index: number;
	value: string;
	entireArray: string[];
	handleChange: (event: InputChangeSignature) => void;
}

const InputArrayTextInputField: React.FC<InputArrayTextInputFieldProps> = ({
	inputObj,
	index,
	value,
	entireArray,
	handleChange,
}) => {
	const { touched, valid, name, placeholder } = inputObj;

	return (
		<input
			className={
				(touched && !valid ? 'border border-red-400 ' : ' shadow ') +
				SHARED_INPUT_CSS_CLASSES +
				' my-1 w-full lg:w-3/4 lg:ml-8' +
				(inputObj.toggle && !inputObj.toggle.disabled ? '  ' : '  ')
			}
			type='text'
			name={`${name}-${index}`}
			// key={`input-${name}-${i}`}
			id={`input-${name}-${index}`}
			value={value}
			placeholder={placeholder}
			onChange={(e) => {
				// serialise back to an array so the form submits one single value for all these inputs
				const newValues = [...entireArray];
				newValues[index] = e.target.value;
				const event = {
					target: {
						name: name,
						value: JSON.stringify(newValues),
					},
				} as React.ChangeEvent<HTMLInputElement>;

				handleChange(event);
			}}
		/>
	);
};

export default InputArrayTextInputField;
