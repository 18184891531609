import React from 'react';
import { FallbackProps } from 'react-error-boundary';

const ErrorFallback: React.FC<FallbackProps> = ({
	error,
	resetErrorBoundary,
}) => (
	<>
		<div className='flex items-center h-full justify-center py-6'>
			<span className='mr-6 border-r-4 border-secondary pr-6'>
				<span className='text-5xl font-serif'>!</span>
			</span>
			<span className='inline-flex flex-col items-center'>
				<h1 className='font-bold'>An unexpected error occurred:</h1>
				<span className='text-lg pl-6 font-serif'>{error.name}</span>
				<span className='text-xs text-gray-500'>{error.message}</span>
			</span>
		</div>
		<div className='mt-12 text-center'>
			<button className='button' onClick={resetErrorBoundary}>
				Try again
			</button>
		</div>
	</>
);

export default ErrorFallback;
