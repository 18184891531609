import React from 'react';
import { IFormField } from '../form_field';
import { InputChangeSignature } from './input_change_signature';
import { SHARED_INPUT_CSS_CLASSES } from './shared_input_details';

export interface InputTextProps {
	inputObj: IFormField;
	handleChange: (event: InputChangeSignature) => void;
	password: boolean;
}

const InputText: React.FC<InputTextProps> = ({
	inputObj,
	handleChange,
	password,
}) => {
	const { touched, valid, value, placeholder, name } = inputObj;

	return (
		<input
			className={
				(touched && !valid ? ' border border-red-400 ' : '') +
				SHARED_INPUT_CSS_CLASSES +
				' shadow ' +
				(inputObj.toggle && !inputObj.toggle.disabled
					? '  w-full lg:w-3/4 '
					: '  w-full lg:w-3/4 ')
			}
			type={password ? 'password' : 'text'}
			name={name}
			id={`input-${name}`}
			value={value}
			placeholder={placeholder}
			onChange={handleChange}
		/>
	);
};

export default InputText;
