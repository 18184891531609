import { capitalizeFirstLetter } from '../../utils/utils';
import { createValidationRule, ValidationRule } from '../validation_rule';

export function maxLengthRule(
	inputName: string,
	maxCharacters: number
): ValidationRule {
	return createValidationRule(
		'maxLength',
		`${capitalizeFirstLetter(
			inputName
		)} cannot contain more than ${maxCharacters} characters`,
		(inputValue, formFields, otherRules) =>
			inputValue.length <= maxCharacters
	);
}
