import { IFormField } from '../../form_field';
import { InputChangeSignature } from '../input_change_signature';

export function createHiddenFormField(name: string, value: string): IFormField {
	return {
		name: name,
		renderInput: (
			handleChange: (event: InputChangeSignature) => void,
			inputObj: IFormField
		) => {
			return (
				<input
					key={`inputContainer-${inputObj.name}`}
					type='hidden'
					value={inputObj.value}
				/>
			);
		},
		label: name,
		value: value,
		valid: true,
		errors: [],
		touched: false,
		validationRules: [],
	};
}
