import { IS_DEV } from '../config/client_config';
import {
	FRUIT_BEARING,
	POLLINATION_GROUPS,
	RATING,
	TIME_TO_MATURITY,
	VarietyType,
	VARIETY_STATUSES,
	VARIETY_TYPES,
} from '../../common/definitions';

import { createDropdownFormField } from '../shared/forms/inputs/create/create_dropdown_form_field';
import { createTextFormField } from '../shared/forms/inputs/create/create_text_form_field';

import { capitalizeFirstLetter } from '../../common/utils/utils';

import AddVarietyFormBase from '../../common/forms/add_variety/add_variety_form_base';

import { nameof } from '../../common/utils/utils';
import { IFormField } from '../shared/forms/form_field';
import AddVarietyFormValidationMap from '../../common/forms/add_variety/add_variety_form_validation_map';
import { createTextArrayFormField } from '../shared/forms/inputs/create/create_text_array_form_field';
import { filterVarietyCategoriesByType } from '../../common/utils/filter_variety_categories_by_type';
import { getFieldFromForm } from '../shared/forms/get_form_fields';
import { HELP_ITEMS } from '../help/help_items';

const validationMap = new AddVarietyFormValidationMap();

export default class AddVarietyFormDefinition implements AddVarietyFormBase {
	[key: string]: IFormField;

	type: IFormField = {
		...createDropdownFormField(
			'Fruit Type',
			nameof<AddVarietyFormBase>('type'),
			VARIETY_TYPES[0],
			VARIETY_TYPES.map((p) => {
				return { display: capitalizeFirstLetter(p), value: p };
			})
		),
		validationRules: { ...validationMap }.type,
		helpItemId: HELP_ITEMS.fruitType,
	};
	name: IFormField = {
		...createTextFormField(
			'Variety Name',
			nameof<AddVarietyFormBase>('name'),
			'text',
			'Name of variety',
			IS_DEV ? 'Dabinett' : ''
		),
		validationRules: { ...validationMap }.name,
		helpItemId: HELP_ITEMS.aka,
	};

	synonyms: IFormField = {
		...createTextArrayFormField(
			'Also known as',
			nameof<AddVarietyFormBase>('synonyms'),
			'Add another name',
			'Name of variety'
		),
		validationRules: { ...validationMap }.synonyms,
		toggle: {
			enableMessage: 'Add Other Names',
			disableMessage: 'Remove all other names',
			disabled: true,
		},
		prompt: 'Is this variety known by any other names?',
		helpItemId: HELP_ITEMS.aka,
	};

	category: IFormField = {
		...createDropdownFormField(
			'Classification',
			nameof<AddVarietyFormBase>('category'),
			filterVarietyCategoriesByType('apple')[0],
			filterVarietyCategoriesByType('apple').map((p) => {
				return { display: capitalizeFirstLetter(p), value: p };
			})
		),
		validationRules: { ...validationMap }.category,
		helpItemId: HELP_ITEMS.classification,
		updateOnFormChange: (allFields, performUpdateOnForm) => {
			// we may have changed the fruit type, so we need to change the fruit subtype too
			const varietyType = getFieldFromForm(
				nameof<AddVarietyFormBase>('type'),
				allFields
			);
			const varietyCategory = getFieldFromForm(
				nameof<AddVarietyFormBase>('category'),
				allFields
			);
			if (varietyType && varietyCategory) {
				const newCategories = filterVarietyCategoriesByType(
					varietyType.value as VarietyType
				);

				varietyCategory.dropdownOptions = newCategories.map((t) => {
					return { display: capitalizeFirstLetter(t), value: t };
				});
				varietyCategory.value = newCategories[0];
				performUpdateOnForm(varietyCategory);
			}
		},
	};
	history: IFormField = {
		...createTextFormField(
			'History',
			nameof<AddVarietyFormBase>('history'),
			'textarea',
			'A brief history of the variety',
			IS_DEV
				? 'Dabinett is a heavy bittersweet that fruits late in the season and produces a reliable crop.'
				: ''
		),
		validationRules: { ...validationMap }.history,
		helpItemId: HELP_ITEMS.history,
	};

	status: IFormField = {
		...createDropdownFormField(
			'Conservation Status',
			nameof<AddVarietyFormBase>('status'),
			VARIETY_STATUSES[0],
			VARIETY_STATUSES.map((p) => {
				return { display: capitalizeFirstLetter(p), value: p };
			})
		),
		validationRules: { ...validationMap }.status,
		helpItemId: HELP_ITEMS.conservationStatus,
	};

	origin: IFormField = {
		...createTextFormField(
			'Origin',
			nameof<AddVarietyFormBase>('origin'),
			'text',
			'Where the variety came from',
			''
		),
		validationRules: { ...validationMap }.origin,
		helpItemId: HELP_ITEMS.origin,
	};

	pollinationGroup: IFormField = {
		...createDropdownFormField(
			'Pollination Group',
			nameof<AddVarietyFormBase>('pollinationGroup'),
			POLLINATION_GROUPS[0],
			POLLINATION_GROUPS.map((p) => {
				return { display: capitalizeFirstLetter(p), value: p };
			})
		),
		validationRules: { ...validationMap }.pollinationGroup,
		prompt: 'Matches the time of year that the tree comes into blossom. Varieties will succeed when grown with other varieties from the same or adjacent pollination groups.',
		helpItemId: HELP_ITEMS.pollinationGroup,
	};

	pestScore: IFormField = {
		...createDropdownFormField(
			'Pest Rating (out of 10)',
			nameof<AddVarietyFormBase>('pestScore'),
			RATING[0].toString(),
			RATING.map((o) => {
				return { display: o.toString(), value: o.toString() };
			})
		),
		validationRules: { ...validationMap }.pestScore,
		prompt: 'A rating out of ten, where ten is most susceptible to pests.',
		helpItemId: HELP_ITEMS.pestRating,
	};

	pests: IFormField = {
		...createTextArrayFormField(
			'Pests',
			nameof<AddVarietyFormBase>('pests'),
			'Add another pest',
			'Name of pest'
		),
		validationRules: { ...validationMap }.pests,
		toggle: {
			enableMessage: 'Add Specific Pests',
			disableMessage: 'Remove all pests',
			disabled: true,
		},
		prompt: 'The most common pests which affect this variety.',
		helpItemId: HELP_ITEMS.pests,
	};

	diseaseScore: IFormField = {
		...createDropdownFormField(
			'Disease Rating (out of 10)',
			nameof<AddVarietyFormBase>('diseaseScore'),
			RATING[0].toString(),
			RATING.map((o) => {
				return { display: o.toString(), value: o.toString() };
			})
		),
		validationRules: { ...validationMap }.diseaseScore,
		prompt: 'A rating out of ten, where ten is most susceptible to diseases.',
		helpItemId: HELP_ITEMS.diseaseRating,
	};

	diseases: IFormField = {
		...createTextArrayFormField(
			'Diseases',
			nameof<AddVarietyFormBase>('diseases'),
			'Add another disease',
			'Name of disease'
		),
		validationRules: { ...validationMap }.diseases,
		toggle: {
			enableMessage: 'Add Specific Diseases',
			disableMessage: 'Remove all diseases',
			disabled: true,
		},
		prompt: 'The most common diseases which affect this variety.',
		helpItemId: HELP_ITEMS.diseases,
	};

	fruitBearing: IFormField = {
		...createDropdownFormField(
			'Fruit Bearing',
			nameof<AddVarietyFormBase>('fruitBearing'),
			FRUIT_BEARING[0],
			FRUIT_BEARING.map((o) => {
				return {
					display: capitalizeFirstLetter(o),
					value: o.toLowerCase(),
				};
			})
		),
		validationRules: { ...validationMap }.fruitBearing,
		helpItemId: HELP_ITEMS.fruitBearing,
	};

	timeToMaturity: IFormField = {
		...createDropdownFormField(
			'Time To Maturity',
			nameof<AddVarietyFormBase>('timeToMaturity'),
			TIME_TO_MATURITY[0],
			TIME_TO_MATURITY.map((o) => {
				return {
					display: capitalizeFirstLetter(o),
					value: o.toLowerCase(),
				};
			})
		),
		validationRules: { ...validationMap }.timeToMaturity,
		helpItemId: HELP_ITEMS.timeToMaturity,
	};
}
