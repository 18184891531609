import SearchResult from '../../common/forms/search/search_results/search_result';
import SearchResultDisplay from '../browse/search_result';
import NoSearchResults from './no_search_results';

export interface SearchResultsListProps {
	result: SearchResult;
}

const SearchResultsList: React.FC<SearchResultsListProps> = ({ result }) => (
	<>
		{result && result.varieties.length === 0 && <NoSearchResults />}
		{result && result.varieties && result.varieties.length > 0 && (
			<div className='mt-6 search-results-list'>
				{result.varieties.map((v, i) => (
					<SearchResultDisplay
						key={`search-result-${i}`}
						index={i}
						summary={v}
					/>
				))}
			</div>
		)}
	</>
);

export default SearchResultsList;
