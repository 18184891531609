import React from 'react';
import StarRatingComponent from 'react-star-rating-controlled-component';
import { BaseProps } from '../../shared/base_props';

export interface StarRatingProps {
	ratingOutOfTen: number;
	size?: string;
}

const StarRating: React.FC<StarRatingProps & BaseProps> = (props) => {
	const { ratingOutOfTen, size, ...rest } = props;

	// let r = Number(ratingOutOfTen).toFixed(1);
	return (
		<span {...rest}>
			<span
				className={
					'text-center ' +
					(size || ' text-sm xl:text-2xl 2xl:text-3xl ')
				}>
				<StarRatingComponent
					name='favourability'
					starCount={5}
					value={Number(ratingOutOfTen / 2)}
					editing={false}
				/>
			</span>
		</span>
	);
};

export default StarRating;
