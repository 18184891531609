import {
	MAXIMUM_SUGAR_LEVEL,
	MINIMUM_SUGAR_LEVEL,
	SugarUnit,
} from '../../definitions';
import { SugarLevelsFieldEntry } from '../../forms/pomona_entry/sugar_levels_field_model';
import {
	brixToSpecificGravity,
	specificGravityToBrix,
} from '../../utils/convert_sugar_levels';
import { isNumeric } from '../../utils/utils';

export function isValidSugarLevelAmount(
	amount: string,
	unit: SugarUnit
): boolean {
	if (!isNumeric(amount)) return false;

	const amountInSg =
		unit === 'specific gravity'
			? amount
			: brixToSpecificGravity(Number(amount));

	return (
		Number(amountInSg) >= MINIMUM_SUGAR_LEVEL &&
		Number(amountInSg) <= MAXIMUM_SUGAR_LEVEL
	);
}

export function isValidSugarLevelEntry(
	levelEntry: SugarLevelsFieldEntry
): boolean {
	return (
		levelEntry.lowestLevel !== '' &&
		isValidSugarLevelAmount(levelEntry.lowestLevel, levelEntry.unit) &&
		levelEntry.highestLevel !== '' &&
		isValidSugarLevelAmount(levelEntry.highestLevel, levelEntry.unit) &&
		levelEntry.lowestLevel < levelEntry.highestLevel
	);
}

export function isEntryImbalanced(entry: SugarLevelsFieldEntry): boolean {
	return (
		entry.lowestLevel !== '' &&
		entry.highestLevel !== '' &&
		Number(entry.lowestLevel) !== undefined &&
		Number(entry.highestLevel) !== undefined &&
		Number(entry.lowestLevel) > Number(entry.highestLevel)
	);
}

export function isAnySugarLevelTooLow(entry: SugarLevelsFieldEntry): boolean {
	const e = convertSugarLevelEntryToUnit(entry, 'specific gravity');

	if (
		entry.lowestLevel &&
		entry.lowestLevel !== '' &&
		isNumeric(entry.lowestLevel) &&
		Number(e.lowestLevel) < MINIMUM_SUGAR_LEVEL
	) {
		return true;
	}

	if (
		entry.highestLevel &&
		entry.highestLevel !== '' &&
		isNumeric(entry.highestLevel) &&
		Number(e.highestLevel) < MINIMUM_SUGAR_LEVEL
	) {
		return true;
	}

	return false;
}

export function isAnySugarLevelTooHigh(entry: SugarLevelsFieldEntry): boolean {
	const e = convertSugarLevelEntryToUnit(entry, 'specific gravity');

	if (
		entry.lowestLevel &&
		entry.lowestLevel !== '' &&
		isNumeric(entry.lowestLevel)
	) {
		if (Number(e.lowestLevel) > MAXIMUM_SUGAR_LEVEL) return true;
	}

	if (
		entry.highestLevel &&
		entry.highestLevel !== '' &&
		isNumeric(entry.highestLevel)
	) {
		if (Number(e.highestLevel) > MAXIMUM_SUGAR_LEVEL) return true;
	}

	return false;
}

export function convertSugarLevelEntryToUnit(
	levelEntry: SugarLevelsFieldEntry,
	desiredUnit: SugarUnit
): SugarLevelsFieldEntry {
	if (levelEntry.unit === desiredUnit) return levelEntry;

	const newEntry = { ...levelEntry };
	newEntry.unit = desiredUnit;

	switch (desiredUnit) {
		case 'brix':
			if (levelEntry.lowestLevel) {
				const newLowest = specificGravityToBrix(
					Number(levelEntry.lowestLevel)
				);
				newEntry.lowestLevel = newLowest
					? newLowest.toFixed(3).toString()
					: '';
			}
			if (levelEntry.highestLevel) {
				const newHighest = specificGravityToBrix(
					Number(levelEntry.highestLevel)
				);
				newEntry.highestLevel = newHighest
					? newHighest.toFixed(3).toString()
					: '';
			}
			break;
		case 'specific gravity':
			if (levelEntry.lowestLevel) {
				const newLowest = brixToSpecificGravity(
					Number(levelEntry.lowestLevel)
				);
				newEntry.lowestLevel = newLowest
					? newLowest.toFixed(3).toString()
					: '';
			}
			if (levelEntry.highestLevel) {
				const newHighest = brixToSpecificGravity(
					Number(levelEntry.highestLevel)
				);
				newEntry.highestLevel = newHighest
					? newHighest.toFixed(3).toString()
					: '';
			}
			break;
	}

	return newEntry;
}
