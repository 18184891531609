import React from 'react';
import ErrorMessagePage from '../shared/ui/error_message_page';
import PageTitle from '../shared/ui/page_title';

import EditVarietyForm from './edit_variety_form';
import { useParams } from 'react-router';
import VarietyViewModel from '../../common/models/variety_view_model';

interface EditVarietyParams {
	encodedVariety: string;
}

const EditVarietyPage: React.FC = () => {
	const { encodedVariety } = useParams<EditVarietyParams>();

	let variety;

	if (encodedVariety) {
		variety = JSON.parse(atob(encodedVariety)) as VarietyViewModel;
	}

	return (
		<div className='text-center'>
			<PageTitle title={'Edit Variety'} />
			{!variety && <ErrorMessagePage message='No variety to edit' />}
			{variety && <EditVarietyForm variety={variety} />}
		</div>
	);
};

export default EditVarietyPage;
