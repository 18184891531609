import { isVarietyCategoryRule } from '../../validation/rules/rule_is_variety_category';
import { isVarietyStatusRule } from '../../validation/rules/rule_is_variety_status';
import { isVarietyTypeRule } from '../../validation/rules/rule_is_variety_type';
import { maxLengthRule } from '../../validation/rules/rule_max_length';
import { minLengthRule } from '../../validation/rules/rule_min_length';
import { requiredRule } from '../../validation/rules/rule_required';
import ValidationMapBase from '../../validation/validation_map_base';
import { ValidationRule } from '../../validation/validation_rule';
import SearchFormBase from './search_form_base';
import { canBeSearchWildcardRule } from '../../validation/rules/rule_can_be_search_wildcard';
import { MINIMUM_SEARCH_LENGTH } from '../../definitions';

export default class SearchFormValidationMap
	extends ValidationMapBase
	implements SearchFormBase {
	name: ValidationRule[] = [
		// requiredRule('name'),
		minLengthRule('name', MINIMUM_SEARCH_LENGTH),
		maxLengthRule('name', 25),
	];

	type: ValidationRule[] = [
		requiredRule('type'),
		canBeSearchWildcardRule(),
		isVarietyTypeRule(),
	];

	category: ValidationRule[] = [
		requiredRule('category'),
		canBeSearchWildcardRule(),
		isVarietyCategoryRule(),
	];
	// history: ValidationRule[] = [
	// 	minLengthRule('history', 10),
	// 	maxLengthRule('history', 500),
	// ];

	status: ValidationRule[] = [
		requiredRule('status'),
		canBeSearchWildcardRule(),
		isVarietyStatusRule(),
	];

	// origin: ValidationRule[] = [isRegionRule()];

	// pollinationGroup: ValidationRule[] = [isPollinationGroupRule()];

	// pestScore: ValidationRule[] = [
	// 	isIntegerRule('pestScore'),
	// 	minValueRule('pestScore', 0),
	// 	maxValueRule('pestScore', 10),
	// ];

	// fruitBearing: ValidationRule[] = [isFruitBearingRule()];

	// growingTime: ValidationRule[] = [minLengthRule('growingTime', 10)];

	// // biennial: ValidationRule[] = [
	// // 	isIntegerRule('biennial'),
	// // 	minValueRule('biennial', 0),
	// // 	maxValueRule('biennial', 10),
	// // ];

	// approved: ValidationRule[] = [];
}
