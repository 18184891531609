import { POLLINATION_GROUPS } from '../../definitions';
import { createValidationRule, ValidationRule } from '../validation_rule';
import { isPollinationGroup } from '../checks/checks';

export function isPollinationGroupRule(): ValidationRule {
	return createValidationRule(
		'pollinationGroup',
		`Must be one of ${POLLINATION_GROUPS.join(', ')}`,
		(inputValue, formFields, otherRules) => isPollinationGroup(inputValue)
	);
}
