import React from 'react';

const RouteMisconfiguration: React.FC = () => (
	<div className='flex items-center h-full justify-center'>
		<span className='mr-6 border-r-4 border-secondary pr-6'>
			<span
				className='text-5xl font-serif'
				style={{ position: 'relative', top: '-6px' }}>
				{/* don't like this vertical centering */}
				Oops
			</span>
		</span>
		<span className='pl-6 font-serif text-sm text-red-500'>
			Misconfiguration in route definitions. Check 'component' is
			correctly spelled (!) and points to a valid object.
		</span>
	</div>
);

export default RouteMisconfiguration;
