export interface BadgeProps {
	[key: string]: any;
}

const RecaptchaBadge: React.FC<BadgeProps> = (props) => {
	return (
		<div className='w-full text-center text-xs my-3 lg:my-6' {...props}>
			This site is protected by reCAPTCHA and the Google{' '}
			<a
				href='https://policies.google.com/privacy'
				target='_blank'
				rel='noopener noreferrer'>
				Privacy Policy
			</a>{' '}
			and{' '}
			<a
				href='https://policies.google.com/terms'
				target='_blank'
				rel='noopener noreferrer'>
				Terms of Service
			</a>{' '}
			apply.
		</div>
	);
};

export default RecaptchaBadge;
