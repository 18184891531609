import React from 'react';
import { MagicSpinner } from 'react-spinners-kit';

const PageLoadingIndicator: React.FC = () => (
	<div className='absolute right-10 bottom-10'>
		<MagicSpinner size={150} color='green' />
	</div>
);

export default PageLoadingIndicator;
