import { VarietyStatus } from '../../common/definitions';

export const isBrowser =
	typeof window !== 'undefined' && typeof window.document !== 'undefined';

export function injectStyle(rule: string): void {
	const styleEl = document.createElement('style') as HTMLStyleElement;
	document.head.appendChild(styleEl);

	const styleSheet = styleEl.sheet as CSSStyleSheet;
	if (styleSheet) styleSheet.insertRule(rule, styleSheet.cssRules.length);
}

export function getVarietyStatusClassName(
	status: VarietyStatus,
	bgOrText: 'bg' | 'text'
): string {
	switch (status) {
		case 'widespread':
			return bgOrText + '-statusWidespread';
		case 'unknown':
			return '';
		case 'uncommon':
			return bgOrText + '-statusUncommon';
		case 'rare':
			return bgOrText + '-statusRare';
		case 'extinct':
			return bgOrText + '-statusExtinct';
		case 'critically-endangered':
			return bgOrText + '-statusEndangered';
	}

	return '';
}
