import { FRUIT_BEARING } from '../../definitions';
import { createValidationRule, ValidationRule } from '../validation_rule';

export function isFruitBearingRule(): ValidationRule {
	return createValidationRule(
		'fruitBearing',
		`Must be one of ${FRUIT_BEARING.join(', ')}`,
		(inputValue, formFields, otherRules) =>
			FRUIT_BEARING.filter((a) => (a as string) === inputValue).length > 0
	);
}
