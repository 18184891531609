import { SugarUnit } from '../../definitions';

export interface SugarLevelsFieldEntry {
	year: number;
	lowestLevel: string;
	highestLevel: string;
	unit: SugarUnit;
}

export function defaultSugarLevelEntry(
	selectedUnit: SugarUnit
): SugarLevelsFieldEntry {
	return {
		year: new Date().getFullYear(),
		lowestLevel: '',
		highestLevel: '',
		unit: selectedUnit,
	};
}

export default class SugarLevelsFieldModel {
	levels: SugarLevelsFieldEntry[] = [];

	constructor(selectedUnit: SugarUnit) {
		this.levels = [];
		this.levels.push(defaultSugarLevelEntry(selectedUnit));
	}
}
