import { capitalizeFirstLetter } from '../../utils/utils';
import { createValidationRule, ValidationRule } from '../validation_rule';

export function isIntegerRule(inputName: string): ValidationRule {
	return createValidationRule(
		'isInteger',
		`${capitalizeFirstLetter(inputName)} must be a whole number`,
		(inputValue, formFields, otherRules) =>
			Number.isInteger(Number.parseInt(inputValue))
	);
}
