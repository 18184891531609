import { isIntegerRule } from '../../validation/rules/rule_is_integer';
import { requiredRule } from '../../validation/rules/rule_required';
import { ValidationRule } from '../../validation/validation_rule';
import AddVarietyFormValidationMap from '../add_variety/add_variety_form_validation_map';

export default class EditVarietyFormValidationMap extends AddVarietyFormValidationMap {
	varietyId: ValidationRule[] = [
		requiredRule('varietyId'),
		isIntegerRule('varietyId'),
	];
}
