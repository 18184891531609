import { useContext, useEffect } from 'react';
import ApprovalsViewModel from '../../../common/server_responses/approvals/approvals_view_model';
import SERVER_ROUTES from '../../../common/server_routes/server_routes';
import computeTotalFromApprovalCountsViewModel from '../../admin/approvals/compute_total_approvals_from_viewmodel';
import ROUTES from '../../routes/client_routes';
import { ADMIN_SUBROUTES } from '../../routes/routes_definitions';
import useServerSubmit from '../../shared/hooks/use_server_submit';
import MustBeLoggedIn from '../../users/must_be_logged_in';
import { UserContext } from '../../users/user_context';
import HeaderLink from './header_link';

const ApprovalsLink: React.FC<{ setMenuOpen: Function }> = ({
	setMenuOpen,
}) => {
	const { result, doSubmit, reset } = useServerSubmit<ApprovalsViewModel, {}>(
		SERVER_ROUTES.FetchApprovalCounts,
		true
	);

	const { currentUser } = useContext(UserContext);

	useEffect(() => {
		if (!currentUser) return;
		const interval = setInterval(() => {
			doSubmit({});
		}, 12 * 1000);
		return () => {
			if (reset) {
				reset();
			}
			clearInterval(interval);
		};
		// eslint-disable-next-line
	}, [doSubmit, currentUser]);

	const total = result ? computeTotalFromApprovalCountsViewModel(result) : 0;

	return (
		<>
			<MustBeLoggedIn>
				<HeaderLink
					setMenuOpen={setMenuOpen}
					text={`Approvals`}
					to={ROUTES.ADMIN + '/' + ADMIN_SUBROUTES.APPROVALS}
					additionalClassName={total > 0 ? ' text-red-800 ' : ''}
				/>
				{total > 0 && (
					<div className='py-1 px-3 bg-red-400 rounded-full text-white font-bold'>
						{total}
					</div>
				)}
			</MustBeLoggedIn>
		</>
	);
};

export default ApprovalsLink;
