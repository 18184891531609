import { useContext, useState } from 'react';
import { UserContext } from '../../users/user_context';
import getFromServer from '../get_from_server';

export default function useServerSubmit<TResult, TBody>(
	route: string,
	requiresUserToken: boolean
) {
	const abortController = new AbortController();

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [hasSubmitted, setHasSubmitted] = useState<boolean>(false);

	const { currentUser } = useContext(UserContext);

	const [result, setResult] = useState<TResult | undefined>(undefined);

	const reset = function () {
		setIsLoading(false);
		setResult(undefined);
		setHasSubmitted(false);
		abortController.abort();
	};

	const doSubmit = async function (body: TBody) {
		if (isLoading) return;

		let userToken = undefined;

		if (requiresUserToken) {
			userToken = await currentUser?.getIdToken();

			if (userToken === undefined) {
				console.log('no token, aborting');
				return;
			}
		}

		setIsLoading(true);
		setHasSubmitted(true);
		const response = await getFromServer<TResult | undefined>(route, {
			...body,
			userToken: userToken,
			abortController,
		});

		if (response.success && response.result) {
			setResult(response.result);
		}

		setIsLoading(false);
	};

	return { doSubmit, reset, isLoading, result, hasSubmitted };
}
