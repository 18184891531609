import React from 'react';

const NotFound: React.FC = () => (
	<div className='flex items-center h-full justify-center'>
		<span className='mr-3 lg:mr-6 border-r-4 border-secondary pr-6'>
			<span
				className='text-xl lg:text-5xl font-serif'
				style={{ position: 'relative', top: '-6px' }}>
				{/* don't like this vertical centering */}
				404
			</span>
		</span>
		<span className='text-sm lg:text-lg pl-3 lg:pl-6 font-serif'>
			Sorry. We can&apos;t find what you&apos;re looking for.
		</span>
	</div>
);

export default NotFound;
