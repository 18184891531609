const ROUTES = {
	ADD_VARIETY: '/add-variety',
	BROWSE: '/browse',
	EDIT_VARIETY: '/edit-variety',
	SEARCH: '/search',
	ADMIN: '/admin',
	HELP: '/help',
};

export default ROUTES;
