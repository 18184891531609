import { useCallback, useState } from 'react';
import { getModelFromForm } from '../forms/get_model_from_form';
import { SubmitStatus } from '../../../common/forms/submit_status';
import ValidationMapBase from '../../../common/validation/validation_map_base';
import { IFormField } from '../forms/form_field';
import { getFormFields } from '../forms/get_form_fields';
import { submitToServer } from '../submit_to_server';
import { getRecaptchaToken } from './get_recaptcha_token';
import useForm from './use_form';

export interface HasRecaptchaToken {
	recaptchaToken?: string;
}

export default function useSubmitForm<
	TModel extends HasRecaptchaToken,
	TValidationMap extends ValidationMapBase,
	TForm,
	TResult
>(route: string, validationMap: TValidationMap, formDefinition: TForm) {
	const [submitStatus, setSubmitStatus] = useState<SubmitStatus<TResult>>({
		submitted: false,
	});

	const [recaptchaReady, setRecaptchaReady] = useState<boolean>(false);

	const handleSubmit = async (fields: IFormField[]) => {
		setSubmitStatus({
			...submitStatus,
			success: undefined,
			submitted: true,
		});
		const values = getModelFromForm<TModel, TValidationMap>(
			fields,
			validationMap
		);

		values.recaptchaToken = await getRecaptchaToken('search');

		const result = await submitToServer<TResult>(route, values);
		setSubmitStatus(result);
	};

	const { renderFormInputs, isFormValid, onSubmit } = useForm(
		getFormFields(formDefinition),
		handleSubmit
	);

	const reset = useCallback(() => {
		setSubmitStatus({
			submitted: false,
			success: undefined,
			message: undefined,
			result: undefined,
		});
	}, []);

	return {
		renderFormInputs,
		isFormValid,
		onSubmit,
		recaptchaReady,
		setRecaptchaReady,
		submitStatus,
		setSubmitStatus,
		reset,
	};
}
