import { capitalizeFirstLetter } from '../../utils/utils';
import { ValidationRule, createValidationRule } from '../validation_rule';

export function requiredRule(inputName: string): ValidationRule {
	return createValidationRule(
		'required',
		`${capitalizeFirstLetter(inputName)} required`,
		(inputValue, formFields, otherRules) =>
			inputValue !== undefined && inputValue.length !== 0
	);
}
