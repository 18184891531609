import { UserContext, IUserContext } from './user_context';
import firebase from 'firebase/app';

import { useEffect, useState } from 'react';

export const UserProvider = ({ children }: any) => {
	let firebaseConfig = {
		apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
		authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
		projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
		storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
		messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
		appId: process.env.REACT_APP_FIREBASE_APP_ID,
		measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
	};

	if (!firebase.apps.length) {
		firebase.initializeApp(firebaseConfig);
		firebase.analytics();
		firebase.auth();
	}

	const [context, setContext] = useState<IUserContext>({
		firebase: firebase.app(),
		authProviders: [],
		updateFirebase: (newFirebase: firebase.app.App) => {
			console.log("You probably don't need to be calling this.");
			setContext({ ...context, firebase: newFirebase });
		},
		currentUser: undefined,
		refreshUser: () => {
			setContext({
				...context,
				currentUser: context.firebase.auth().currentUser,
			});
		},
	});

	useEffect(() => {
		firebase.auth().onAuthStateChanged((newUser) => {
			// console.log(
			// 	`changed ${context.currentUser} to ${
			// 		newUser?.displayName ? newUser.displayName : newUser
			// 	}`
			// );
			setContext({ ...context, currentUser: newUser });
		});
		// eslint-disable-next-line
	}, []);

	return (
		<div>
			<UserContext.Provider value={context}>
				{children}
			</UserContext.Provider>
		</div>
	);
};
