import { IFormField } from './form_field';

export function getFormFields(form: any): IFormField[] {
	const allFields: IFormField[] = [];
	for (const field of Object.keys(form)) {
		allFields.push(form[field]);
	}
	return allFields;
}

export function getFieldFromForm(
	name: string,
	form: IFormField[]
): IFormField | undefined {
	return form.find((a) => a.name === name);
}
