import { ServerResponse } from '../../common/server_responses/server_response';
import { JSON_to_URLEncoded } from '../../common/utils/other_utils';
import { clientConfig } from '../config/client_config';

export default async function getFromServer<T>(
	serverRoute: string,
	body?: any,
	abortController?: AbortController
): Promise<ServerResponse<T>> {
	let result: ServerResponse<T> = { success: false };

	const url = `${clientConfig.nodeProtocol}://${clientConfig.nodeServer}:${clientConfig.nodePort}/${serverRoute}`;

	try {
		let response = await fetch(url, {
			signal: abortController?.signal,
			method: 'POST',
			mode: 'cors',
			cache: 'no-cache',
			headers: {
				Accept: 'application/x-www-form-urlencoded',
				'Content-Type': 'application/x-www-form-urlencoded',
			},
			body: body ? JSON_to_URLEncoded(body) : undefined,
		});

		if (response.status === 200) {
			const r = (await response.json()) as ServerResponse<T>;
			return r;
		} else {
			return result;
		}
	} catch (err) {
		if (abortController?.signal.aborted) {
			// not really an error, we have aborted the request
			return { success: false };
		}

		console.error(err);
		return result;
	}
}
