import React, { useState } from 'react';
import { IFormField } from '../../../form_field';
import { InputChangeSignature } from './../../input_change_signature';
import SugarLevelsFieldModel, {
	defaultSugarLevelEntry,
} from '../../../../../../common/forms/pomona_entry/sugar_levels_field_model';
import { SugarUnit } from '../../../../../../common/definitions';

import SugarUnitSelect from './sugar_levels/sugar_unit_select';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import SugarLevelsEnteredRow from './sugar_levels/sugar_levels_entered_row';
import SugarLevelsEditRow from './sugar_levels/sugar_levels_edit_row';

export interface InputSugarlevelsProps {
	inputObj: IFormField;
	handleChange: (event: InputChangeSignature) => void;
}

const InputSugarlevels: React.FC<InputSugarlevelsProps> = ({
	inputObj,
	handleChange,
}) => {
	const { value, name, valid } = inputObj;

	const [selectedUnit, setSelectedUnit] = useState<SugarUnit>(
		'specific gravity'
	);

	const [localTouched, setLocalTouched] = useState<{
		lowest: boolean;
		highest: boolean;
	}>({ lowest: false, highest: false });

	let initial = value;
	if (!value) {
		initial = JSON.stringify(new SugarLevelsFieldModel(selectedUnit));
	}

	const sugarLevels = JSON.parse(initial) as SugarLevelsFieldModel;

	const currentEntryIndex = sugarLevels.levels.length - 1;
	const currentEntry = sugarLevels.levels[currentEntryIndex];

	return (
		<div className='flex flex-col w-full pt-3 lg:pt-6'>
			<SugarUnitSelect
				handleChange={handleChange}
				sugarLevels={sugarLevels}
				currentEntryIndex={currentEntryIndex}
				setSelectedUnit={setSelectedUnit}
				name={name}
			/>
			<table className='table-auto'>
				<thead>
					<tr>
						<td>Year</td>
						<td>Lowest ({selectedUnit})</td>
						<td>Highest ({selectedUnit})</td>
					</tr>
				</thead>
				<tbody>
					{sugarLevels.levels.slice(0, -1).map((level, i) => (
						<SugarLevelsEnteredRow
							key={`sugarLevelsInput-${i}`}
							rowIndex={i}
							level={level}
							handleChange={handleChange}
							name={name}
							selectedUnit={selectedUnit}
							sugarLevels={sugarLevels}
						/>
					))}
					<SugarLevelsEditRow
						handleChange={handleChange}
						setLocalTouched={setLocalTouched}
						localTouched={localTouched}
						currentEntry={currentEntry}
						currentEntryIndex={currentEntryIndex}
						sugarLevels={sugarLevels}
						selectedUnit={selectedUnit}
						name={name}
					/>
				</tbody>
			</table>

			<button
				disabled={!valid}
				className='smallLink mt-4 justify-self-center inline-flex items-center justify-center'
				onClick={(e) => {
					e.preventDefault();

					sugarLevels.levels.push(
						defaultSugarLevelEntry(selectedUnit)
					);
					const event = {
						target: {
							name: name,
							value: JSON.stringify(sugarLevels),
						},
					} as React.ChangeEvent<HTMLInputElement>;
					handleChange(event);

					setLocalTouched({ lowest: false, highest: false });
				}}>
				<FontAwesomeIcon
					icon='plus-circle'
					color={valid ? '#A7F3D0' : '#ccc'}
					size='2x'
				/>
				<span
					className={
						(valid ? 'text-green-700' : 'text-gray-500') + ' ml-3'
					}>
					{inputObj.addMoreLabel || 'Add another sugar level'}
				</span>
			</button>
		</div>
	);
};

export default InputSugarlevels;
