import React from 'react';
import { IFormField } from '../form_field';
import { InputChangeSignature } from './input_change_signature';
import { SHARED_INPUT_CSS_CLASSES } from './shared_input_details';

export interface InputSelectProps {
	inputObj: IFormField;
	handleChange: (event: InputChangeSignature) => void;
}

const InputSelect: React.FC<InputSelectProps> = ({
	inputObj,
	handleChange,
}) => {
	const { touched, valid, value, dropdownOptions, name } = inputObj;

	return (
		<select
			className={
				(touched && !valid ? ' border border-red-400 ' : '') +
				SHARED_INPUT_CSS_CLASSES +
				' shadow ' +
				(inputObj.toggle && !inputObj.toggle.disabled
					? ' w-full lg:w-3/4 '
					: ' w-full lg:w-3/4 ')
			}
			name={name}
			id={`select-${name}`}
			value={value}
			onChange={handleChange}>
			{dropdownOptions?.map((o) => (
				<option key={`select-${name}-value-${o.value}`} value={o.value}>
					{o.display}
				</option>
			))}
			{/* <option value='wrong'>wrong answer test</option> */}
		</select>
	);
};

export default InputSelect;
