import { capitalizeFirstLetter } from '../../utils/utils';
import { createValidationRule, ValidationRule } from '../validation_rule';

export function maxValueRule(
	inputName: string,
	maxValue: number
): ValidationRule {
	return createValidationRule(
		'maxValue',
		`${capitalizeFirstLetter(
			inputName
		)} cannot be greater than ${maxValue}`,
		(inputValue, formFields, otherRules) =>
			Number.parseFloat(inputValue) <= maxValue
	);
}
