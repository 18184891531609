import ApprovalsViewModel from '../../../common/server_responses/approvals/approvals_view_model';
import { ApprovalsTabType } from './approvals_tab_types';

export default function computeTotalFromApprovalCountsViewModel(
	vm: ApprovalsViewModel
): number {
	if (!vm) return 0;

	const result =
		Number((vm.varietyIds || []).length) +
		Number((vm.pomonaEntryIds || []).length) +
		Number((vm.sugarLevelIds || []).length) +
		Number(vm.wordcloudWordsCount || 0) +
		Number((vm.pendingVarietyEditIds || []).length) +
		Number((vm.imageIds || []).length);

	return result;
}

export function hasApprovalForTab(
	vm: ApprovalsViewModel,
	tab: ApprovalsTabType
): boolean {
	switch (tab) {
		case 'New Varieties':
			return vm.varietyIds && vm.varietyIds.length > 0;

		case 'Variety Edits':
			return (
				vm.pendingVarietyEditIds && vm.pendingVarietyEditIds.length > 0
			);

		case 'Pomona Entries':
			return vm.pomonaEntryIds && vm.pomonaEntryIds.length > 0;
		case 'Wordclouds':
			return vm.wordcloudWordsCount > 0;
		case 'Sugar Levels':
			return vm.sugarLevelIds && vm.sugarLevelIds.length > 0;
		case 'Images':
			return vm.imageIds && vm.imageIds.length > 0;
	}
	return false;
}
