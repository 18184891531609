import { SugarUnit } from '../../../../../../../common/definitions';
import SugarLevelsFieldModel, {
	SugarLevelsFieldEntry,
} from '../../../../../../../common/forms/pomona_entry/sugar_levels_field_model';
import { isValidSugarLevelAmount } from '../../../../../../../common/validation/functions/validate_sugar_levels';
import SugarLevelsSpliceButton from './sugar_levels_splice_button';
import SugarLevelsYearSelect from './sugar_levels_year_select';

export interface SugarLevelsEditRowProps {
	localTouched: { lowest: boolean; highest: boolean };
	currentEntry: SugarLevelsFieldEntry;
	currentEntryIndex: number;
	sugarLevels: SugarLevelsFieldModel;
	selectedUnit: SugarUnit;
	name: string;
	handleChange: Function;
	setLocalTouched: Function;
}

const SugarLevelsEditRow: React.FC<SugarLevelsEditRowProps> = ({
	localTouched,
	currentEntry,
	currentEntryIndex,
	sugarLevels,
	selectedUnit,
	name,
	handleChange,
	setLocalTouched,
}) => (
	<tr>
		<td>
			<SugarLevelsYearSelect
				sugarLevels={sugarLevels}
				handleChange={handleChange}
				currentEntryIndex={currentEntryIndex}
				name={name}
			/>
		</td>
		<td>
			<input
				name={`sugarLevel-lowest`}
				style={{ width: '9rem' }}
				type='text'
				className={
					(localTouched.lowest &&
					!isValidSugarLevelAmount(
						currentEntry.lowestLevel,
						currentEntry.unit
					)
						? 'border border-red-400 '
						: '') +
					' shadow py-1 px-1 appearance-none border rounded text-grey-700'
				}
				id={`input-sugarLevel-lowest`}
				value={currentEntry.lowestLevel}
				placeholder='Lowest Level'
				onChange={(e) => {
					const newEntry = { ...currentEntry };
					newEntry.lowestLevel = e.target.value;
					newEntry.unit = selectedUnit;
					sugarLevels.levels[currentEntryIndex] = newEntry;

					const event = {
						target: {
							name: name,
							value: JSON.stringify(sugarLevels),
						},
					} as React.ChangeEvent<HTMLInputElement>;
					handleChange(event);

					setLocalTouched({
						...localTouched,
						lowest: true,
					});
				}}
			/>
		</td>
		<td>
			<input
				name={`sugarLevel-highest`}
				style={{ width: '9rem' }}
				type='text'
				className={
					(localTouched.highest &&
					!isValidSugarLevelAmount(
						currentEntry.highestLevel,
						currentEntry.unit
					)
						? 'border border-red-400 '
						: '') +
					' shadow py-1 px-1 appearance-none border rounded text-gray-600'
				}
				id={`input-sugarLevel-highest`}
				value={currentEntry.highestLevel}
				placeholder='Highest Level'
				onChange={(e) => {
					const newEntry = { ...currentEntry };
					newEntry.highestLevel = e.target.value;
					newEntry.unit = selectedUnit;
					sugarLevels.levels[currentEntryIndex] = newEntry;

					const event = {
						target: {
							name: name,
							value: JSON.stringify(sugarLevels),
						},
					} as React.ChangeEvent<HTMLInputElement>;
					handleChange(event);
					setLocalTouched({
						...localTouched,
						highest: true,
					});
				}}
			/>
		</td>

		<td>
			<SugarLevelsSpliceButton
				indexToSplice={sugarLevels.levels.length - 1}
				sugarLevels={sugarLevels}
				name={name}
				handleChange={handleChange}
			/>
		</td>
	</tr>
);

export default SugarLevelsEditRow;
