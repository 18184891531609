import React, { useContext, useEffect } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import Layout from '../layouts/layout';
import PageLoadingIndicator from '../shared/loading/page_loading_indicator';
import NotFound from '../shared/ui/not_found';
import RouteMisconfiguration from '../shared/ui/route_misconfiguration';
import { UserContext } from '../users/user_context';
import routes_definitions from './routes_definitions';

const Routes = () => {
	let location = useLocation();
	let { firebase, currentUser } = useContext(UserContext);

	useEffect(() => {
		if (!firebase) return;

		const page_path = location.pathname + location.search;
		firebase.analytics().setCurrentScreen(page_path);

		firebase.analytics().logEvent('page_view', {
			page_path,
		});
		// eslint-disable-next-line
	}, [location]);

	return (
		<Layout>
			{/* let firebase figure out if we're logged in or not before rendering ANY children */}
			{currentUser === undefined && <PageLoadingIndicator />}
			{/* currentUser starts undefined, then will be null if logged out, set if logged in */}
			{currentUser !== undefined && (
				<Switch>
					{routes_definitions
						// .filter((a) => a.layoutType === 'home')
						.map((r) => (
							<Route
								exact={r.exact}
								key={r.name + Math.random()}
								path={r.path}
								component={r.component || RouteMisconfiguration}
							/>
						))}

					<Route component={NotFound} />
				</Switch>
			)}
		</Layout>
	);
};

export default Routes;
