import React from 'react';
import { IFormField } from './form_field';
import InputArray from './inputs/array/input_array';
import InputSugarlevels from './inputs/create/pomona/input_sugar_levels';
import { InputChangeSignature } from './inputs/input_change_signature';
import InputCheckbox from './inputs/input_checkbox';
import InputDatepicker from './inputs/input_datepicker';
import InputSelect from './inputs/input_select';
import InputText from './inputs/input_text';
import InputTextarea from './inputs/input_textarea';

import { InputType } from './inputs/input_types';

export interface InnerInputProps {
	type: InputType;
	handleChange: (event: InputChangeSignature) => void;
	inputObj: IFormField;
}

const InnerInput: React.FC<InnerInputProps> = ({
	type,
	handleChange,
	inputObj,
}) => (
	<>
		{type === 'text' && (
			<InputText
				handleChange={handleChange}
				inputObj={inputObj}
				password={false}
			/>
		)}
		{type === 'password' && (
			<InputText
				handleChange={handleChange}
				inputObj={inputObj}
				password
			/>
		)}
		{type === 'textarea' && (
			<InputTextarea handleChange={handleChange} inputObj={inputObj} />
		)}
		{type === 'datepicker' && (
			<InputDatepicker handleChange={handleChange} inputObj={inputObj} />
		)}
		{type === 'dropdown' && (
			<InputSelect handleChange={handleChange} inputObj={inputObj} />
		)}
		{type === 'checkbox' && (
			<InputCheckbox handleChange={handleChange} inputObj={inputObj} />
		)}
		{(type === 'textarray' || type === 'datepickerarray') && (
			<InputArray
				handleChange={handleChange}
				inputObj={inputObj}
				type={type}
			/>
		)}
		{type === 'sugarlevels' && (
			<InputSugarlevels handleChange={handleChange} inputObj={inputObj} />
		)}
	</>
);

export default InnerInput;
