import React from 'react';
import { IFormField } from '../form_field';
import { InputChangeSignature } from './input_change_signature';

export interface InputCheckboxProps {
	inputObj: IFormField;
	handleChange: (event: InputChangeSignature) => void;
}

const InputCheckbox: React.FC<InputCheckboxProps> = ({
	inputObj,
	handleChange,
}) => {
	const { touched, valid, value, name } = inputObj;

	return (
		<input
			className={
				(touched && !valid ? ' border border-red-400 ' : '') +
				' form-checkbox h-4 lg:h-7 w-4 lg:w-7 text-green-600 '
			}
			type='checkbox'
			name={name}
			id={`input-${name}`}
			value={value}
			onChange={(e) => {
				const event = {
					target: {
						name: inputObj.name,
						value: e.target.checked.toString(),
					},
				} as React.ChangeEvent<HTMLInputElement>;
				handleChange(event);
			}}
		/>
	);
};

export default InputCheckbox;
