import { library } from '@fortawesome/fontawesome-svg-core';
import * as Icons from '@fortawesome/free-solid-svg-icons';

export default function importIcons() {
	const iconList = Object.keys(Icons)
		.filter((key) => key !== 'fas' && key !== 'prefix')
		.map((icon) => Icons[icon]);

	library.add(...iconList);
}
