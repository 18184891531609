import React from 'react';
import { VarietyType } from '../../../common/definitions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faAppleAlt,
	faLeaf,
	faSeedling,
} from '@fortawesome/free-solid-svg-icons';

export interface VarietyTypeProps {
	type: VarietyType;
}

const selectIcon = (type: VarietyType): JSX.Element => {
	switch (type) {
		case 'apple':
			return <FontAwesomeIcon icon={faAppleAlt} />;
		case 'pear':
			return <FontAwesomeIcon icon={faLeaf} />;
		case 'other':
			return <FontAwesomeIcon icon={faSeedling} />;
		default:
			return <></>;
	}
};

const VarietyTypeIcon: React.FC<VarietyTypeProps> = ({ type }) => (
	<span className='text-primary mb-1 lg:mb-3 text-lg lg:text-2xl'>
		{selectIcon(type)}
	</span>
);

export default VarietyTypeIcon;
