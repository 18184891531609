import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useHistory } from 'react-router';

export interface SuccessPageProps {
	message: string;
	showBackButton?: boolean;
}

const SuccessPage: React.FC<SuccessPageProps> = ({
	message,
	showBackButton = true,
}) => {
	const history = useHistory();

	return (
		<>
			<div className='flex items-center h-full justify-center pt-6'>
				<span className='mr-3 lg:mr-6 border-r-4 border-secondary pr-6'>
					<span className='text-xl lg:text-5xl font-serif'>
						<FontAwesomeIcon
							icon='thumbs-up'
							color='green'
							size='6x'
						/>{' '}
					</span>
				</span>
				<span className='inline-flex flex-col items-center'>
					<span className='text-sm lg:text-lg pl-3 lg:pl-6 font-serif'>
						{message}
					</span>
					{/* <span className='text-xs text-gray-500'>
						{detailedError}
					</span> */}
				</span>
			</div>
			{showBackButton && history.length > 1 && (
				<div className='mt-3 lg:mt-6 text-center'>
					<button
						className='button'
						onClick={(e) => {
							e.preventDefault();
							history.goBack();
						}}>
						Return
					</button>
				</div>
			)}
		</>
	);
};

export default SuccessPage;
