import React, { MouseEventHandler } from 'react';

export interface LinkButtonProps {
	text: string;
	onClick: MouseEventHandler;
}

const LinkButton: React.FC<LinkButtonProps> = ({ text, onClick }) => (
	<button
		className='text-link hover:text-linkHover self-center text-sm mt-0'
		onClick={onClick}>
		{text}
	</button>
);

export default LinkButton;
