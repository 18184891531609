import { BrowserRouter } from 'react-router-dom';
import ScrollToTop from '../shared/scroll_to_top';

import Routes from './routes';

const Router: React.FC = () => {
	return (
		<BrowserRouter>
			<ScrollToTop />
			<Routes />
		</BrowserRouter>
	);
};

export default Router;
