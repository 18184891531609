import { SEARCH_WILDCARD, TIME_TO_MATURITY } from '../../definitions';
import { isTimeToMaturity } from '../checks/checks';
import {
	createValidationRule,
	includesRule,
	ValidationRule,
} from '../validation_rule';
import { CanBeSearchWildcardRule } from './rule_can_be_search_wildcard';

export function isTimeToMaturityRule(): ValidationRule {
	return createValidationRule(
		'timeToMaturity',
		`Must be one of ${TIME_TO_MATURITY.join(', ')}`,
		(inputValue, formFields, otherRules) =>
			isTimeToMaturity(inputValue) ||
			(includesRule(otherRules, CanBeSearchWildcardRule) &&
				inputValue === SEARCH_WILDCARD)
	);
}
