import { lazy } from 'react';
import Browse from '../browse/browse';
import { clientConfig } from '../config/client_config';
import EditVarietyPage from '../edit_variety/edit_variety_page';
import Search from '../search/search';
import ROUTES from './client_routes';

export const ADMIN_SUBROUTES = {
	CREATE_USER: 'create-user',
	APPROVALS: 'approvals',
	SIGN_IN: 'sign-in',
	DEV: clientConfig.isDev ? 'dev' : '',
};

const Home = lazy(() => import('../home/home'));
const About = lazy(() => import('../about/about'));
const Sponsor = lazy(() => import('../sponsor/sponsor'));
const VarietyEmpty = lazy(() => import('../variety/variety_empty'));
const VarietyDetailPage = lazy(
	() => import('../variety_detail/variety_detail_page')
);
const AddVariety = lazy(() => import('../add_variety/add_variety'));
const AddPomonaEntryPage = lazy(
	() => import('../add_pomona_entry/add_pomona_entry_page')
);

const Help = lazy(() => import('../help/help'));

const Contact = lazy(() => import('../contact/contact'));
const Privacy = lazy(() => import('../privacy/privacy'));

const AdminPage = lazy(() => import('../admin/admin_page/admin_page'));

// when adding a new route, don't forget to add the new path at all_paths for the sitemap generator
// (it blows up if you try and import the components at all, so can't use the same data structure
// so some duplication is sadly necessary)

const routes_definitions = [
	{
		exact: true,
		path: '/',
		canonical: '/',
		name: 'home',
		component: Home,
		// layoutType: 'home',
	},
	{
		path: '/about',
		canonical: '/about',
		name: 'about',
		component: About,
	},
	{
		path: '/privacy',
		canonical: '/privacy',
		name: 'privacy',
		component: Privacy,
	},
	{
		path: '/contact',
		canonical: '/contact',
		name: 'contact',
		component: Contact,
	},
	{
		path: '/sponsor',
		canonical: '/sponsor',
		name: 'sponsor',
		component: Sponsor,
	},
	{
		path: '/variety',
		exact: true,
		canonical: '/variety',
		name: 'variety',
		component: VarietyEmpty,
	},
	{
		path: '/variety/:id',
		canonical: '/variety',
		name: 'variety',
		component: VarietyDetailPage,
	},
	{
		path: '/add-pomona-entry/:id',
		canonical: '/add-pomona-entry',
		name: 'addPomonaEntry',
		component: AddPomonaEntryPage,
	},
	{
		path: ROUTES.ADMIN + '/:subroute?',
		canonical: ROUTES.ADMIN,
		name: 'admin',
		component: AdminPage,
	},
	{
		path: ROUTES.ADD_VARIETY,
		canonical: ROUTES.ADD_VARIETY,
		name: ROUTES.ADD_VARIETY,
		component: AddVariety,
	},
	{
		path: ROUTES.EDIT_VARIETY + '/:encodedVariety?',
		canonical: ROUTES.EDIT_VARIETY,
		name: ROUTES.EDIT_VARIETY,
		component: EditVarietyPage,
	},
	{
		path: ROUTES.BROWSE + '/:letter?',
		canonical: ROUTES.BROWSE,
		name: ROUTES.BROWSE,
		component: Browse,
	},
	{
		path: ROUTES.SEARCH + '/:name?',
		canonical: ROUTES.SEARCH,
		name: ROUTES.SEARCH,
		component: Search,
	},
	{
		path: ROUTES.HELP,
		canonical: ROUTES.HELP,
		name: ROUTES.HELP,
		component: Help,
	},
];

export default routes_definitions;
