import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { HelmetProvider } from 'react-helmet-async';
import ErrorFallback from './shared/error_fallback';

import UserWrapper from './user_wrapper';

function App() {
	return (
		<HelmetProvider>
			<ErrorBoundary FallbackComponent={ErrorFallback}>
				<UserWrapper />
			</ErrorBoundary>
		</HelmetProvider>
	);
}

export default App;
