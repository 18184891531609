import { IS_DEV } from '../config/client_config';
import {
	SEARCH_WILDCARD,
	VARIETY_CATEGORIES,
	VARIETY_STATUSES,
	VARIETY_TYPES,
} from '../../common/definitions';
import { createDropdownFormField } from '../shared/forms/inputs/create/create_dropdown_form_field';
import { createTextFormField } from '../shared/forms/inputs/create/create_text_form_field';
import { capitalizeFirstLetter } from '../../common/utils/utils';
import { nameof } from '../../common/utils/utils';
import { IFormField } from '../shared/forms/form_field';
import SearchFormBase from '../../common/forms/search/search_form_base';
import SearchFormValidationMap from '../../common/forms/search/search_form_validation_map';

const validationMap = new SearchFormValidationMap();

export default class SearchFormDefinition implements SearchFormBase {
	[key: string]: IFormField;

	name: IFormField = {
		...createTextFormField(
			'',
			nameof<SearchFormBase>('name'),
			'text',
			'Name',
			IS_DEV ? 'Dabinett' : ''
		),
		icon: 'search',
		validationRules: { ...validationMap }.name,
	};

	type: IFormField = {
		...createDropdownFormField(
			'Fruit Type',
			nameof<SearchFormBase>('type'),
			SEARCH_WILDCARD,
			[
				{ display: 'All', value: SEARCH_WILDCARD },
				...VARIETY_TYPES.map((p) => {
					return { display: capitalizeFirstLetter(p), value: p };
				}),
			]
		),
		validationRules: { ...validationMap }.type,
	};

	category: IFormField = {
		...createDropdownFormField(
			'Category',
			nameof<SearchFormBase>('category'),
			SEARCH_WILDCARD,
			[
				{ display: 'All', value: SEARCH_WILDCARD },
				...VARIETY_CATEGORIES.map((p) => {
					return { display: capitalizeFirstLetter(p), value: p };
				}),
			]
		),
		validationRules: { ...validationMap }.category,
	};
	// history: IFormField = {
	// 	...createTextFormField(
	// 		'History',
	// 		nameof<AddVarietyFormBase>('history'),
	// 		'textarea',
	// 		'A brief history of the variety',
	// 		IS_DEV
	// 			? 'Dabinett is a heavy bittersweet that fruits late in the season and produces a reliable crop.'
	// 			: ''
	// 	),
	// 	validationRules: { ...validationMap }.history,
	// };

	status: IFormField = {
		...createDropdownFormField(
			'Conservation Status',
			nameof<SearchFormBase>('status'),
			SEARCH_WILDCARD,
			[
				{ display: 'All', value: SEARCH_WILDCARD },
				...VARIETY_STATUSES.map((p) => {
					return { display: capitalizeFirstLetter(p), value: p };
				}),
			]
		),
		validationRules: { ...validationMap }.status,
	};

	// origin: IFormField = {
	// 	...createDropdownFormField(
	// 		'Origin',
	// 		nameof<AddVarietyFormBase>('origin'),
	// 		REGIONS[0],
	// 		REGIONS.map((p) => {
	// 			return { display: capitalizeFirstLetter(p), value: p };
	// 		})
	// 	),
	// 	validationRules: { ...validationMap }.origin,
	// };

	// pollinationGroup: IFormField = {
	// 	...createDropdownFormField(
	// 		'Pollination Group',
	// 		nameof<AddVarietyFormBase>('pollinationGroup'),
	// 		POLLINATION_GROUPS[0],
	// 		POLLINATION_GROUPS.map((p) => {
	// 			return { display: p, value: p };
	// 		})
	// 	),
	// 	validationRules: { ...validationMap }.pollinationGroup,
	// };

	// pestScore: IFormField = {
	// 	...createDropdownFormField(
	// 		'Pest Rating (out of 10)',
	// 		nameof<AddVarietyFormBase>('pestScore'),
	// 		'0',
	// 		[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((o) => {
	// 			return { display: o.toString(), value: o.toString() };
	// 		})
	// 	),
	// 	validationRules: { ...validationMap }.pestScore,
	// };

	// pests: IFormField = {
	// 	...createTextArrayFormField(
	// 		'Pests',
	// 		nameof<AddVarietyFormBase>('pests'),
	// 		'Add another pest',
	// 		'Name of pest'
	// 	),
	// 	validationRules: { ...validationMap }.pests,
	// 	toggle: {
	// 		enableMessage: 'Add Specific Pests',
	// 		disableMessage: 'Remove all pests',
	// 		disabled: true,
	// 	},
	// };

	// diseaseScore: IFormField = {
	// 	...createDropdownFormField(
	// 		'Disease Rating (out of 10)',
	// 		nameof<AddVarietyFormBase>('diseaseScore'),
	// 		'1',
	// 		[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((o) => {
	// 			return { display: o.toString(), value: o.toString() };
	// 		})
	// 	),
	// 	validationRules: { ...validationMap }.diseaseScore,
	// };

	// diseases: IFormField = {
	// 	...createTextArrayFormField(
	// 		'Diseases',
	// 		nameof<AddVarietyFormBase>('diseases'),
	// 		'Add another disease',
	// 		'Name of disease'
	// 	),
	// 	validationRules: { ...validationMap }.diseases,
	// 	toggle: {
	// 		enableMessage: 'Add Specific Diseases',
	// 		disableMessage: 'Remove all diseases',
	// 		disabled: true,
	// 	},
	// };

	// fruitBearing: IFormField = {
	// 	...createDropdownFormField(
	// 		'Fruit Bearing',
	// 		nameof<AddVarietyFormBase>('fruitBearing'),
	// 		'tip',
	// 		['Tip', 'Spur'].map((o) => {
	// 			return { display: o, value: o.toLowerCase() };
	// 		})
	// 	),
	// 	validationRules: { ...validationMap }.fruitBearing,
	// };

	// growingTime: IFormField = {
	// 	...createTextFormField(
	// 		'Growing Time',
	// 		nameof<AddVarietyFormBase>('growingTime'),
	// 		'text'
	// 	),
	// 	validationRules: { ...validationMap }.growingTime,
	// };
}
