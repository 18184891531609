import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SugarLevelsFieldModel from '../../../../../../../common/forms/pomona_entry/sugar_levels_field_model';

export interface SugarLevelsSpliceButtonProps {
	sugarLevels: SugarLevelsFieldModel;
	name: string;
	handleChange: Function;
	indexToSplice: number;
}

const SugarLevelsSpliceButton: React.FC<SugarLevelsSpliceButtonProps> = ({
	sugarLevels,
	name,
	handleChange,
	indexToSplice,
}) => {
	const disabled =
		sugarLevels.levels.length <= 1 ||
		indexToSplice > sugarLevels.levels.length - 1;
	return (
		<button
			disabled={disabled}
			className={'ml-4 ' + (disabled ? 'text-gray-500' : 'text-red-600')}
			onClick={(e) => {
				e.preventDefault();

				sugarLevels.levels.splice(indexToSplice, 1);
				const event = {
					target: {
						name: name,
						value: JSON.stringify(sugarLevels),
					},
				} as React.ChangeEvent<HTMLInputElement>;
				handleChange(event);
			}}>
			<FontAwesomeIcon icon={faTimesCircle} />
		</button>
	);
};

export default SugarLevelsSpliceButton;
