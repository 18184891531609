import React, { useEffect } from 'react';
import RecaptchaBadge from './hooks/recaptcha_badge';

import { useRecaptcha } from './hooks/use_recaptcha';

const RecaptchaComponent: React.FC<{
	action: string;
	sitekey: string;
	onReady: () => void;
	hideText: boolean;
}> = ({ action, sitekey, onReady, hideText }) => {
	// we don't need to getToken here
	const { ready } = useRecaptcha({
		sitekey,
		hideDefaultBadge: true,
	});

	useEffect(() => {
		// const doGetToken = async () => {
		// 	// const token = await getToken(action);
		// 	// onToken(token);
		// };

		if (ready) {
			onReady();
		}
	}, [ready, onReady]);

	return hideText ? <></> : <RecaptchaBadge />;
};

export default RecaptchaComponent;
