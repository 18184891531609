export const HELP_ITEMS = {
	fruitType: 'fruitType',
	varietyName: 'varietyName',
	aka: 'aka',
	classification: 'classification',
	history: 'history',
	conservationStatus: 'conservationStatus',
	origin: 'origin',
	pollinationGroup: 'pollinationGroup',
	pestRating: 'pestRating',
	pests: 'pests',
	diseaseRating: 'diseaseRating',
	diseases: 'diseases',
	fruitBearing: 'fruitBearing',
	timeToMaturity: 'timeToMaturity',
	favourability: 'favourability',
	meanPh: 'meanPh',
	biennialRating: 'biennialRating',
	blossomHarvest: 'blossomHarvest',
	sugarLevels: 'sugarLevels',
	flavourDescriptors: 'flavourDescriptors',
	ripenessIndicators: 'ripenessIndicators',
	growingCharacteristics: 'growingCharacteristics',
};
