import { createValidationRule, ValidationRule } from '../validation_rule';

export const CanBeSearchWildcardRule = 'canBeSearchWildcardRule';

export function canBeSearchWildcardRule(): ValidationRule {
	return createValidationRule(
		CanBeSearchWildcardRule,
		`This rule cannot be failed individually.`,
		(inputValue, formFields, otherRules) => true
	);
}
