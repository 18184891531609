import { useState, useCallback, SyntheticEvent } from 'react';
import { IFormField } from '../forms/form_field';
import { validateField } from '../forms/validation/validate_field';

export function useForm(formObj: IFormField[], submitCallback: Function) {
	const [form, setForm] = useState<IFormField[]>(formObj);

	function renderFormInputs() {
		return Object.values(form).map((inputObj) => {
			const { renderInput } = inputObj;
			return renderInput(onInputChange, inputObj);
		});
	}

	const onSubmit = useCallback(
		(e: SyntheticEvent) => {
			e.preventDefault();

			// if a field is toggled off, replace the default value with null so the server knows nothing
			// has been submitted
			// (we kept the default value up to now in case the user wanted to toggle the field back on)
			const formToSubmit = form.map((f) =>
				f.toggle === undefined || f.toggle.disabled === false
					? f
					: { ...f, value: null }
			);

			submitCallback(formToSubmit);
		},
		[form, submitCallback]
	);

	const isInputFieldValid = useCallback(
		(inputField: IFormField) => {
			inputField.errors = [];

			if (inputField.toggle && inputField.toggle.disabled) {
				return true;
			} else {
				let { valid, errors } = validateField(inputField, form);
				inputField.errors = errors;
				return valid;
			}
		},
		[form]
	);

	const onInputChange = useCallback(
		(event, allowAllFieldsToRespond = true) => {
			const { name, value } = event.target;

			// copy input object whose value was changed
			const inputObj: IFormField = {
				...(form.find((f) => f.name === name) as IFormField),
			};

			// update value
			inputObj.value = value;

			inputObj.valid = isInputFieldValid(inputObj);

			// mark input field as touched
			inputObj.touched = true;

			const newForm = form.map(
				(obj) => [inputObj].find((i) => i.name === obj.name) || obj
			);

			if (allowAllFieldsToRespond) {
				// notify each field there's been an update
				newForm.forEach((field) => {
					if (field.name !== inputObj.name) {
						if (field.updateOnFormChange) {
							field.updateOnFormChange(newForm, (newField) => {
								// a field has updated itself in response to the other update
								// update the form  *without* notifying all the other fields
								onInputChange({ target: newField }, false);
							});
						}
					}
				});
			}

			setForm([...newForm]);
		},
		[form, isInputFieldValid]
	);

	const isFormValid = useCallback(() => {
		let isValid = true;
		const arr = Object.values(form);

		for (let i = 0; i < arr.length; i++) {
			if (!isInputFieldValid(arr[i])) {
				isValid = false;

				break;
			}
		}

		return isValid;
	}, [form, isInputFieldValid]);

	return { renderFormInputs, isFormValid, isInputFieldValid, onSubmit };
}

export default useForm;
