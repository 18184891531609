import { capitalizeFirstLetter } from '../../../common/utils/utils';

const HistorySnippet: React.FC<{ history: string }> = ({ history }) => {
	const wordsToShow = 16;
	const splitByWord = history.split(' ');
	const isExcerpt = splitByWord.length > wordsToShow;

	return (
		<>
			{capitalizeFirstLetter(
				splitByWord.slice(0, wordsToShow).join(' ') +
					(isExcerpt ? '...' : '')
			)}
		</>
	);
};

export default HistorySnippet;
