import {
	MAXIMUM_HISTORY_LENGTH,
	MAXIMUM_ORIGIN_PLACE_LENGTH,
	MAXIMUM_VARIETY_NAME_LENGTH,
	MINIMUM_HISTORY_LENGTH,
	MINIMUM_VARIETY_NAME_LENGTH,
} from '../../definitions';
import AddVarietyFormBase from '../../forms/add_variety/add_variety_form_base';
import { isArrayRule } from '../../validation/rules/rule_is_array';
import { isCorrectVarietySubcategoryForAddVarietyFormRule } from '../../validation/rules/rule_is_correct_variety_subcategory';
import { isFruitBearingRule } from '../../validation/rules/rule_is_fruit_bearing';
import { isIntegerRule } from '../../validation/rules/rule_is_integer';
import { isPollinationGroupRule } from '../../validation/rules/rule_is_pollenation_group';
import { isTimeToMaturityRule } from '../../validation/rules/rule_is_time_to_maturity';
import { isVarietyCategoryRule } from '../../validation/rules/rule_is_variety_category';
import { isVarietyStatusRule } from '../../validation/rules/rule_is_variety_status';
import { isVarietyTypeRule } from '../../validation/rules/rule_is_variety_type';
import { maxLengthRule } from '../../validation/rules/rule_max_length';
import { maxValueRule } from '../../validation/rules/rule_max_value';
import { minLengthRule } from '../../validation/rules/rule_min_length';
import { minValueRule } from '../../validation/rules/rule_min_value';
import { requiredRule } from '../../validation/rules/rule_required';
import ValidationMapBase from '../../validation/validation_map_base';
import { ValidationRule } from '../../validation/validation_rule';

export default class AddVarietyFormValidationMap
	extends ValidationMapBase
	implements AddVarietyFormBase
{
	type: ValidationRule[] = [requiredRule('type'), isVarietyTypeRule()];

	name: ValidationRule[] = [
		requiredRule('name'),
		minLengthRule('name', MINIMUM_VARIETY_NAME_LENGTH),
		maxLengthRule('name', MAXIMUM_VARIETY_NAME_LENGTH),
	];

	synonyms: ValidationRule[] = [
		isArrayRule('synonyms'),
		minLengthRule('Name', MINIMUM_VARIETY_NAME_LENGTH),
		maxLengthRule('Name', MAXIMUM_VARIETY_NAME_LENGTH),
	];

	category: ValidationRule[] = [
		requiredRule('category'),
		isVarietyCategoryRule(),
		isCorrectVarietySubcategoryForAddVarietyFormRule(),
	];
	history: ValidationRule[] = [
		minLengthRule('history', MINIMUM_HISTORY_LENGTH),
		maxLengthRule('history', MAXIMUM_HISTORY_LENGTH),
	];

	status: ValidationRule[] = [isVarietyStatusRule()];

	origin: ValidationRule[] = [
		minLengthRule('origin', 4),
		maxLengthRule('origin', MAXIMUM_ORIGIN_PLACE_LENGTH),
	];

	pollinationGroup: ValidationRule[] = [isPollinationGroupRule()];

	pestScore: ValidationRule[] = [
		isIntegerRule('pestScore'),
		minValueRule('pestScore', 0),
		maxValueRule('pestScore', 10),
	];

	pests: ValidationRule[] = [
		isArrayRule('pests'),
		minLengthRule('pests', MINIMUM_VARIETY_NAME_LENGTH),
		maxLengthRule('pests', MAXIMUM_VARIETY_NAME_LENGTH),
	];

	diseaseScore: ValidationRule[] = [
		isIntegerRule('diseaseScore'),
		minValueRule('diseaseScore', 0),
		maxValueRule('diseaseScore', 10),
	];

	diseases: ValidationRule[] = [
		isArrayRule('diseases'),
		minLengthRule('diseases', MINIMUM_VARIETY_NAME_LENGTH),
		maxLengthRule('diseases', MAXIMUM_VARIETY_NAME_LENGTH),
	];

	fruitBearing: ValidationRule[] = [isFruitBearingRule()];

	timeToMaturity: ValidationRule[] = [isTimeToMaturityRule()];

	approved: ValidationRule[] = [];
}
