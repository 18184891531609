import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ROUTES from '../routes/client_routes';

const ScrollToTop: React.FC = () => {
	const { pathname } = useLocation();

	useEffect(() => {
		try {
			if (pathname !== ROUTES.HELP) {
				// new scroll API - https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo
				window.scroll({
					top: 0,
					left: 0,
					behavior: 'smooth',
				});
			}
		} catch (error) {
			// fallback for older browsers
			window.scrollTo(0, 0);
		}
	}, [pathname]);

	return null;
};

export default ScrollToTop;
