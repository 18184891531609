import React, { useState } from 'react';
import { clientConfig } from '../../config/client_config';
import NameSearchBox from '../../home/name_search_box';
import { ADMIN_SUBROUTES } from '../../routes/routes_definitions';

import LogoSvg from '../logo_svg';
import ApprovalsLink from './approvals_link';

import HeaderLink from './header_link';
import LoggedInIndicator from './logged_in_indicator';
import { useMediaQuery } from 'react-responsive';
import ROUTES from '../../routes/client_routes';

const Header: React.FC = () => {
	const [menuOpen, setMenuOpen] = useState<boolean>(false);

	const isWideEnough = useMediaQuery({ minWidth: 768 });

	return (
		<header>
			<nav className='flex items-center justify-between flex-wrap bg-primaryFaded p-6'>
				<div className='flex items-center flex-no-shrink mr-6'>
					<LogoSvg />
					<span className='text-2xl md:text-3xl tracking-tight font-serif mx-3'>
						Our Pomona
					</span>
				</div>
				<div className='block md:hidden'>
					<button
						onClick={() => setMenuOpen(!menuOpen)}
						className='flex items-center px-3 py-2 rounded text-teal-lighter border-teal-light hover:text-white hover:border-white'>
						<svg
							className='h-3 w-3'
							viewBox='0 0 20 20'
							xmlns='http://www.w3.org/2000/svg'>
							<title>Menu</title>
							<path d='M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z' />
						</svg>
					</button>
				</div>
				{(isWideEnough || (!isWideEnough && menuOpen)) && (
					<div className='w-full block flex-grow md:flex md:items-center md:w-auto'>
						<div className='text-sm md:flex-grow'>
							<HeaderLink
								to='/'
								text='Home'
								setMenuOpen={setMenuOpen}
							/>
							<HeaderLink
								to={ROUTES.BROWSE}
								text='Browse'
								setMenuOpen={setMenuOpen}
							/>
							<HeaderLink
								to={ROUTES.ADD_VARIETY}
								text='Add Variety'
								setMenuOpen={setMenuOpen}
							/>
							<HeaderLink
								to='/search'
								text='Search'
								setMenuOpen={setMenuOpen}
							/>
						</div>
						{clientConfig.isDev && isWideEnough && (
							<div className='invisible 2xl:visible text-sm 2xl:py-3 2xl:px-4'>
								<HeaderLink
									to={
										ROUTES.ADMIN + '/' + ADMIN_SUBROUTES.DEV
									}
									text='Dev'
									setMenuOpen={setMenuOpen}
								/>
							</div>
						)}
						{isWideEnough && <NameSearchBox />}

						<HeaderLink
							to='/sponsor'
							text='Sponsor'
							setMenuOpen={setMenuOpen}
						/>
						<HeaderLink
							to='/about'
							text='About'
							setMenuOpen={setMenuOpen}
						/>
						<ApprovalsLink setMenuOpen={setMenuOpen} />
						<LoggedInIndicator />
					</div>
				)}
			</nav>
		</header>
	);
};

export default Header;
