import React from 'react';
import { useMediaQuery } from 'react-responsive';

import { IFormField } from './form_field';
import InputHelpLink from './inputs/input_help_link';
import { InputChangeSignature } from './inputs/input_change_signature';
import InputLabel from './inputs/input_label';
import { InputType } from './inputs/input_types';
import ToggledInput from './toggled_input';

export interface InputProps {
	type: InputType;
	handleChange: (event: InputChangeSignature) => void;
	inputObj: IFormField;
}

const Input: React.FC<InputProps> = ({ type, handleChange, inputObj }) => {
	const { errors, valid, touched, helpItemId } = inputObj;
	const isWide = useMediaQuery({ minWidth: 1024 });

	return (
		<div className='form-field '>
			<div className='flex flex-col lg:flex-row justify-start items-center'>
				<InputLabel inputObj={inputObj} />
				<ToggledInput
					type={type}
					inputObj={inputObj}
					handleChange={handleChange}
				/>
				{isWide && helpItemId && (
					<InputHelpLink helpItemId={helpItemId} />
				)}
			</div>
			{errors.length > 0 && !valid && touched && (
				<div className='text-xs bg-red-400 text-white w-full text-center inline-flex justify-evenly items-center flex-col lg:flex-row py-2'>
					{errors.map((e, i) => (
						<span
							key={`${inputObj.name}-errors-${i}`}
							className='py-2'>
							{e}
						</span>
					))}
				</div>
			)}
		</div>
	);
};

export default Input;
