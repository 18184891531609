export const IS_DEV = process.env.NODE_ENV === 'development';

export const clientConfig = {
	isDev: IS_DEV,
	nodePort: IS_DEV ? 8008 : 443, // heroku starts the server with a random port but we communicate via 443 (or 80)
	nodeServer: IS_DEV ? 'localhost' : 'our-pomona-server.herokuapp.com',
	nodeProtocol: IS_DEV ? 'http' : 'https',
	recaptchaSiteKey:
		process.env.REACT_APP_RECAPTCHA_SITE || 'RecaptchaKeyMissing',
};
