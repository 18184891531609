const SERVER_ROUTES = {
	FetchVariety: 'fetch-variety',
	FetchVarietyCounts: 'fetch-variety-counts',
	FetchFeaturedVariety: 'fetch-featured-variety',
	FetchBrowse: 'fetch-browse',
	FetchVarietySummary: 'fetch-variety-summary',

	// admin routes
	FetchApprovalCounts: 'fetch-approvals',
	FetchNewVarietiesToApprove: 'fetch-approvals-new-varieties',
	SubmitApproveNewVariety: 'submit-approve-new-variety',
	FetchNewPomonaEntriesToApprove: 'fetch-approvals-new-pomona-entries',
	SubmitApproveNewPomonaEntry: 'submit-approve-new-pomona-entry',
	FetchVarietyEditsToApprove: 'fetch-approvals-variety-edits',
	SubmitApproveVarietyEdit: 'submit-approve-variety-edit',
	FetchSugarLevelsToApprove: 'fetch-approvals-sugar-levels',
	SubmitApproveSugarLevel: 'submit-approve-sugar-level',
	FetchWordsToApprove: 'fetch-approvals-words',
	SubmitApproveWord: 'submit-approve-word',
	FetchImagesToApprove: 'fetch-approvals-images',
	SubmitApproveImage: 'submit-approve-image',

	VarietyExists: 'does-variety-exist',

	SubmitAddVariety: 'submit-add-variety',
	SubmitEditVariety: 'submit-edit-variety',
	SubmitPomonaEntry: 'submit-pomona-entry',
	SubmitToWordCloud: 'submit-word-cloud',

	Search: 'submit-search',

	UploadImage: 'upload-image',

	// admin-dev
	SubmitReseedDb: 'submit-reseed-db',
	SubmitWipeDb: 'submit-wipe-db',
};

export default SERVER_ROUTES;
