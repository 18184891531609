import React from 'react';
import { NavLink } from 'react-router-dom';
import ROUTES from '../../routes/client_routes';
import RecaptchaBadge from '../../shared/hooks/recaptcha_badge';

import LogoSvg from '../logo_svg';

const Footer: React.FC = () => (
	<footer className='bg-secondaryFaded text-center'>
		<div className='container mx-auto pt-3'>
			<div className='flex flex-wrap overflow-hidden sm:-mx-1 md:-mx-px lg:-mx-2 xl:-mx-2'>
				<div className='w-full inline-flex justify-center items-center overflow-hidden sm:my-1 sm:px-1 sm:w-1/2 md:my-px md:px-px md:w-1/2 lg:my-2 lg:px-2 lg:w-1/4 xl:my-2 xl:px-2 xl:w-1/4 pb-3'>
					<LogoSvg />
					<h2 className='font-bold font-serif text-md lg:text-xl ml-3'>
						Our Pomona
					</h2>
				</div>

				<div className='w-full overflow-hidden sm:my-1 sm:px-1 sm:w-1/2 md:my-px md:px-px md:w-1/2 lg:my-2 lg:px-2 lg:w-1/4 xl:my-2 xl:px-2 xl:w-1/4'>
					<ul className='nav navbar-nav'>
						<li id='navi-2' className='leading-7 text-sm'>
							<NavLink
								to={ROUTES.HELP}
								className='underline text-xs lg:text-sm'>
								Help
							</NavLink>
						</li>
						<li id='navi-2' className='leading-7 text-sm'>
							<NavLink
								to='/about'
								className='underline text-xs lg:text-sm'>
								About{' '}
							</NavLink>
						</li>
					</ul>
				</div>

				<div className='w-full overflow-hidden sm:my-1 sm:px-1 sm:w-1/2 md:my-px md:px-px md:w-1/2 lg:my-2 lg:px-2 lg:w-1/4 xl:my-2 xl:px-2 xl:w-1/4'>
					<ul className=''>
						<li className='leading-7 text-xs lg:text-sm'>
							<NavLink
								to='/contact'
								className='underline text-xs lg:text-sm'>
								Contact{' '}
							</NavLink>
						</li>
						<li className='leading-7 text-xs lg:text-sm'>
							<NavLink
								to='/privacy'
								className='underline text-xs lg:text-sm'>
								Privacy{' '}
							</NavLink>
						</li>
					</ul>
				</div>
			</div>

			<div
				className='mt-6 lg:mt-0 pt-3 md:flex md:items-center md:justify-center text-xs lg:text-sm flex-row'
				style={{ borderTop: '1px solid black' }}>
				<span className='mx-3'>
					Site owner:{' '}
					<a className='underline' href='https://rosscider.com/'>
						Albert Johnson
					</a>
				</span>
				<span className='mx-3'>
					Site developed by:{' '}
					<a className='underline' href='https://enhughesiasm.com'>
						Neil Hughes
					</a>
				</span>
			</div>
			<div className='px-12 my-12'>
				<RecaptchaBadge />
			</div>
		</div>
	</footer>
);

export default Footer;
