export function brixToSpecificGravity(brix: number): number | undefined {
	if (!brix) return undefined;

	// sg = 0.00000005785037196 * brix3 + 0.00001261831344 * brix2 + 0.003873042366 * brix + 0.9999994636
	return (
		0.00000005785037196 * Math.pow(brix, 3) +
		0.00001261831344 * Math.pow(brix, 2) +
		0.003873042366 * brix +
		0.9999994636
	);
}

export function specificGravityToBrix(sg?: number): number | undefined {
	if (!sg) return undefined;
	// approximated by a 3rd degree polynomial (!)
	// 143.254 * sg3 - 648.670 * sg2 + 1125.805 * sg - 620.389
	// but doesn't work if sg is above 1.17 or below, say, 1

	if (sg < 1 || sg > 1.18) return undefined;
	return (
		143.254 * Math.pow(sg, 3) -
		648.67 * Math.pow(sg, 2) +
		1125.805 * sg -
		620.389
	);
}
