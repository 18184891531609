import React from 'react';
import DatePicker from 'react-date-picker';
import { IFormField } from '../../form_field';
import { InputChangeSignature } from '../input_change_signature';
import { SHARED_INPUT_CSS_CLASSES } from '../shared_input_details';

export interface InputArrayDatepickerInputFieldProps {
	inputObj: IFormField;
	index: number;
	value: string;
	entireArray: string[];
	handleChange: (event: InputChangeSignature) => void;
}

const InputArrayDatepickerInputField: React.FC<InputArrayDatepickerInputFieldProps> = ({
	inputObj,
	index,
	value,
	entireArray,
	handleChange,
}) => {
	const { touched, valid, name } = inputObj;

	return (
		<DatePicker
			className={
				(touched && !valid ? ' border border-red-400 ' : '') +
				SHARED_INPUT_CSS_CLASSES +
				(inputObj.toggle && !inputObj.toggle.disabled
					? ' w-3/5 lg:w-1/3 '
					: ' w-3/5 lg:w-1/3 ')
			}
			onChange={(date) => {
				// convert from react-date-picker date to ISO string, for simplicity server-side
				const d = new Date(date.toString()).toISOString();

				// serialise back to an array so the form submits one single value for all these inputs
				const newValues = [...entireArray];
				newValues[index] = d;
				const event = {
					target: {
						name: name,
						value: JSON.stringify(newValues),
					},
				} as React.ChangeEvent<HTMLInputElement>;

				handleChange(event);
			}}
			value={new Date(value)}
			name={`${name}-${index}`}
			// id={`input-${name}-${index}`}
		/>
	);
};

export default InputArrayDatepickerInputField;
