import { SugarUnit } from '../../../../../../../common/definitions';
import SugarLevelsFieldModel, {
	SugarLevelsFieldEntry,
} from '../../../../../../../common/forms/pomona_entry/sugar_levels_field_model';
import { isValidSugarLevelAmount } from '../../../../../../../common/validation/functions/validate_sugar_levels';
import { displaySugarAmount } from '../../../../../ui/display_sugar_amount';
import SugarLevelsSpliceButton from './sugar_levels_splice_button';

export interface SugarLevelsEnteredRowProps {
	level: SugarLevelsFieldEntry;
	selectedUnit: SugarUnit;
	rowIndex: number;
	sugarLevels: SugarLevelsFieldModel;
	handleChange: Function;
	name: string;
}

const SugarLevelsEnteredRow: React.FC<SugarLevelsEnteredRowProps> = ({
	name,
	handleChange,
	level,
	selectedUnit,
	rowIndex,
	sugarLevels,
}) => (
	<tr>
		<td className='font-bold'>{level.year}</td>
		<td
			className={
				isValidSugarLevelAmount(level.lowestLevel, level.unit)
					? ''
					: 'text-red-700'
			}>
			{displaySugarAmount(level.lowestLevel, level.unit, selectedUnit)}(
			{selectedUnit === 'specific gravity' ? 'sg' : 'brix'})
		</td>
		<td
			className={
				isValidSugarLevelAmount(level.highestLevel, level.unit)
					? ''
					: 'text-red-700'
			}>
			{displaySugarAmount(level.highestLevel, level.unit, selectedUnit)}(
			{selectedUnit === 'specific gravity' ? 'sg' : 'brix'})
		</td>
		<td>
			<SugarLevelsSpliceButton
				indexToSplice={rowIndex}
				handleChange={handleChange}
				name={name}
				sugarLevels={sugarLevels}
			/>
		</td>
	</tr>
);

export default SugarLevelsEnteredRow;
