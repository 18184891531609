import firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/auth';
import React from 'react';

export interface IUserContext {
	firebase: firebase.app.App;
	authProviders: string[];
	updateFirebase: (firebase: firebase.app.App) => void;
	currentUser: undefined | firebase.User | null; // unknown - logged in - logged out
	refreshUser: () => void;
}

export const UserContext = React.createContext({} as IUserContext);
