import React from 'react';

import InputArrayTextInputField from './input_array_text_input_field';
import InputArrayAddFieldButton from './input_array_add_field_button';
import InputArrayRemoveFieldButton from './input_array_remove_field_button';
import { IFormField } from '../../form_field';
import { InputChangeSignature } from '../input_change_signature';
import { InputType } from '../input_types';
import InputArrayDatepickerInputField from './input_array_datepicker_input_field';

export interface InputArrayProps {
	type: InputType;
	inputObj: IFormField;
	handleChange: (event: InputChangeSignature) => void;
}

const InputArray: React.FC<InputArrayProps> = ({
	inputObj,
	type,
	handleChange,
}) => {
	const { value } = inputObj;

	let initial = value;
	if (!value) {
		initial = '[""]';
	}

	const entireArray = JSON.parse(initial) as string[];

	return (
		<div className='flex flex-col w-full'>
			{entireArray.map((v, i) => (
				<div
					key={`textarray-${inputObj.name}-${i}`}
					className='w-full inline-flex flex-row justify-center'>
					{type === 'textarray' && (
						<InputArrayTextInputField
							inputObj={inputObj}
							value={v}
							index={i}
							entireArray={entireArray}
							handleChange={handleChange}
						/>
					)}
					{type === 'datepickerarray' && (
						<InputArrayDatepickerInputField
							inputObj={inputObj}
							value={v || new Date().toISOString()}
							index={i}
							entireArray={entireArray}
							handleChange={handleChange}
						/>
					)}
					{type !== 'datepickerarray' && type !== 'textarray' && (
						<span>Not implemented.</span>
					)}
					<InputArrayRemoveFieldButton
						inputObj={inputObj}
						index={i}
						entireArray={entireArray}
						handleChange={handleChange}
					/>
				</div>
			))}
			<InputArrayAddFieldButton
				inputObj={inputObj}
				entireArray={entireArray}
				handleChange={handleChange}
				defaultValue={
					type === 'datepickerarray' ? new Date().toISOString() : ''
				}
			/>
		</div>
	);
};

export default InputArray;
