import { capitalizeFirstLetter } from '../../utils/utils';
import { createValidationRule, ValidationRule } from '../validation_rule';

export function minLengthRule(
	inputName: string,
	minCharacters: number
): ValidationRule {
	return createValidationRule(
		'minLength',
		`${capitalizeFirstLetter(
			inputName
		)} should contain at least ${minCharacters} characters`,
		(inputValue, formFields, otherRules) =>
			inputValue.length >= minCharacters
	);
}
