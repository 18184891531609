import {
	FRUIT_BEARING,
	POLLINATION_GROUPS,
	REGIONS,
	TIME_TO_MATURITY,
	VARIETY_CATEGORIES,
	VARIETY_STATUSES,
	VARIETY_TYPES,
} from '../../definitions';

export function isVarietyType(value: string): boolean {
	return VARIETY_TYPES.filter((a) => (a as string) === value).length > 0;
}

export function isVarietyStatus(value: string): boolean {
	return VARIETY_STATUSES.filter((a) => (a as string) === value).length > 0;
}

export function isVarietyCategory(value: string): boolean {
	return VARIETY_CATEGORIES.filter((a) => (a as string) === value).length > 0;
}

export function isTimeToMaturity(value: string): boolean {
	return TIME_TO_MATURITY.filter((a) => (a as string) === value).length > 0;
}

export function isPollinationGroup(value: string): boolean {
	return POLLINATION_GROUPS.filter((a) => (a as string) === value).length > 0;
}

export function isRegion(value: string): boolean {
	return REGIONS.filter((a) => (a as string) === value).length > 0;
}

export function isFruitBearing(value: string): boolean {
	return FRUIT_BEARING.filter((a) => (a as string) === value).length > 0;
}

// TK: this isn't perfect but it'll do for now
export function isValidEmail(value: string): boolean {
	return (
		value !== undefined &&
		value !== '' &&
		/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value)
	);
}

// also not perfect but it'll do for now
export function containsSpecialCharacters(value: string): boolean {
	return (
		value !== undefined &&
		value !== '' &&
		/[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(value)
	);
}
