import { SEARCH_WILDCARD, VarietyType } from '../../definitions';
import AddVarietyFormBase from '../../forms/add_variety/add_variety_form_base';
import { filterVarietyCategoriesByType } from '../../utils/filter_variety_categories_by_type';
import { nameof } from '../../utils/utils';

import {
	createValidationRule,
	includesRule,
	ValidationRule,
} from '../validation_rule';
import { CanBeSearchWildcardRule } from './rule_can_be_search_wildcard';

export function isCorrectVarietySubcategoryForAddVarietyFormRule(): ValidationRule {
	return createValidationRule(
		'varietyCategoryMatchesType',
		`Category is not the correct fruit!`, // this error message would be unhelpful to a user, but if it ever appears it's a bug
		(inputValue, formFields, otherRules) => {
			// we might be doing a search for 'all' in which case this doesn't matter
			const isWildcard =
				includesRule(otherRules, CanBeSearchWildcardRule) &&
				inputValue === SEARCH_WILDCARD;
			if (isWildcard) return true;

			// if we don't have a fruit type to compare to, something is wrong
			const varietyType = formFields.find((a) =>
				nameof<AddVarietyFormBase>('type')
			);
			if (!varietyType) return false;

			// check we match
			const allowedCategories = filterVarietyCategoriesByType(
				varietyType.value as VarietyType
			);
			return (
				allowedCategories.filter((a) => (a as string) === inputValue)
					.length > 0
			);
		}
	);
}
