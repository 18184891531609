import React, { FunctionComponent, Suspense } from 'react';
import { useMediaQuery } from 'react-responsive';
import PageLoadingIndicator from '../shared/loading/page_loading_indicator';
import Footer from './footer/footer';
import Header from './header/header';

const Layout: FunctionComponent = ({ children }) => {
	const isMedium = useMediaQuery({ minWidth: 768 });

	return (
		<div className='flex flex-col min-h-screen'>
			{/* {process.env.NODE_ENV != 'development' && (
					<HelmetContainer />						
				)} */}
			<Header />
			<main className='flex-grow flex flex-wrap overflow-hidden '>
				{isMedium && (
					<div className='w-eighth lg:w-1/6 xl:w-1/4 overflow-hidden'></div>
				)}

				<div className='w-full my-6 pb-6 px-3 md:w-3/4 lg:w-2/3 xl:w-1/2 '>
					<Suspense fallback={<PageLoadingIndicator />}>
						{children}
					</Suspense>
				</div>

				{isMedium && (
					<div className='w-eighth lg:w-1/6 xl:w-1/4 overflow-hidden'></div>
				)}
			</main>
			<Footer />
		</div>
	);
};

export default Layout;
