import {
	APPLE_CATEGORIES,
	OTHER_CATEGORIES,
	PEAR_CATEGORIES,
	VarietyCategory,
	VarietyType,
} from '../definitions';

export function filterVarietyCategoriesByType(
	varietyType: VarietyType
): VarietyCategory[] {
	switch (varietyType) {
		case 'apple':
			return APPLE_CATEGORIES;
		case 'pear':
			return PEAR_CATEGORIES;
		case 'other':
			return OTHER_CATEGORIES;
	}
}
