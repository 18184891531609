import {
	SugarUnit,
	SUGAR_UNITS,
} from '../../../../../../../common/definitions';
import SugarLevelsFieldModel from '../../../../../../../common/forms/pomona_entry/sugar_levels_field_model';
import { capitalizeFirstLetter } from '../../../../../../../common/utils/utils';
import { convertSugarLevelEntryToUnit } from '../../../../../../../common/validation/functions/validate_sugar_levels';

export interface SugarUnitSelectProps {
	handleChange: Function;
	sugarLevels: SugarLevelsFieldModel;
	currentEntryIndex: number;
	name: string;
	setSelectedUnit: Function;
}

const SugarUnitSelect: React.FC<SugarUnitSelectProps> = ({
	handleChange,
	setSelectedUnit,
	name,
	sugarLevels,
	currentEntryIndex,
}) => (
	<div className='mt-2 lg:mt-3 mb-2 lg:mb-3'>
		<label className='text-bold mx-2 lg:mx-3'>Display in: </label>
		<select
			className='w-48 my-2 lg:my-3 py-1 lg:py-2 self-center'
			onChange={(e) => {
				const newUnit = e.target.value;

				const n = newUnit as SugarUnit;
				const newEntry = convertSugarLevelEntryToUnit(
					sugarLevels.levels[currentEntryIndex],
					n
				);

				sugarLevels.levels[currentEntryIndex] = newEntry;

				const event = {
					target: {
						name: name,
						value: JSON.stringify(sugarLevels),
					},
				} as React.ChangeEvent<HTMLInputElement>;
				handleChange(event);

				setSelectedUnit(newUnit);
			}}>
			{SUGAR_UNITS.map((su, i) => (
				<option key={`sugarLevelUnit-${i}`} value={su}>
					{capitalizeFirstLetter(su)}
				</option>
			))}
		</select>
	</div>
);

export default SugarUnitSelect;
