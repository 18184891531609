import { IFormField } from '../../form_field';
import Input from '../../input';
import DropdownOption from '../dropdown_option';
import { InputChangeSignature } from '../input_change_signature';

export function createDropdownFormField(
	label: string,
	name: string,
	defaultValue: string,
	options: DropdownOption[]
): IFormField {
	return {
		name: name,
		renderInput: (
			handleChange: (event: InputChangeSignature) => void,
			inputObj: IFormField
		) => {
			return (
				<Input
					key={`inputContainer-${inputObj.name}`}
					type={'dropdown'}
					handleChange={handleChange}
					inputObj={inputObj}
				/>
			);
		},
		label,
		value: defaultValue,
		valid: true,
		errors: [],
		touched: false,
		validationRules: [],
		dropdownOptions: options,
	};
}
